/* General Styles */
.invoice-container {
  color: #333;
 
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.maininvoice-container{

  border: 1px solid #5555;
  position: relative;
  height: 1124px;
  background-color: white;
}

.logo-invoice {
  width: 120px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 2px solid #ddd; */
  padding-bottom: 10px;
}

.invoice-title {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 37px;
}

/* Billing and Shipping Details */
.billing-shipping-details {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.gradient-line {
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, #1cc0d9 50%, #fcb423 50%);
}

.billing-shipping-details h4 {

  font-weight: bold;
  color: #555;
  padding-bottom: 6px;
}
.invoice-sidecolumn p {
  text-align: right;
}

.billing-shipping-details p {
  /* margin: 5px 0; */
  font-size: 10px;
}

/* Product Table */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  text-align: left;
  padding: 10px;
  font-size: 16px;

}
.disclaimer {
  padding-bottom: 20px;
  font-size: 10px;
}
.invoice-table th {
  font-weight: bold;
  color: #555;
  border-bottom: 1px solid #5555;
  padding-top: 40px;
}
tr.summary-row td {
  padding: 5px 10px;
}
/* Summary Section */
.invoice-summary {
  text-align: right;
  margin-top: 20px;
}
.firstsummeryrow{

  border-top: 1px solid #5555;
}
.invoice-summary p {
  font-size: 10px;
  margin: 5px 0;
  font-weight: bold;
}

/* Footer Section */
.invoice-footer {
  margin-top: 170px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.invoice-footer .footer-logo {
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.invoice-footer .footer-divider {
  height: 2px;
  background-color: #ddd;
  margin: 10px 0;
}
.company-details p img {
  padding-left: 12px;
}
.company-details {
  font-size: 10px;
}

.invoice-footer .footer-icon {
  text-align: center;
  font-size: 12px;
  color: #777;
  background: #1f1f2b;
  padding: 5px 0px;
}
.company-details {
  text-align: right;
}

/* Buttons */
.button-container {
  margin-top: 20px;
  text-align: center;
}

.button-container .back-button,
.button-container .download-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.button-container .back-button {
  background-color: #6c757d;
  color: white;
}

.button-container .download-button {
  background-color: #007bff;
  color: white;
}

.button-container .back-button:hover {
  background-color: #5a6268;
}

.button-container .download-button:hover {
  background-color: #0056b3;
}
