.productDetail {
  .product-overview {
    margin-top: 5rem;
    margin-bottom: 3rem;
    .item-detail {
      .reviewsContainer {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .colors {
        margin: 1rem 0;
        .colors-container {
          margin-top: 1rem;
          .color-box {
            height: 2.125rem;
            width: 2.125rem;
          }
        }
      }

      .size-container {
        .selected-color {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1.5rem;

          > .title {
            margin-right: 1rem;
          }

          .color-box {
            margin-bottom: 0;
          }
        }
        .sizes {
          margin-bottom: 0.5rem;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          gap: 3rem;
          ul {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            padding: 0;
            li {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2.188rem;
              min-width: 2.438rem;
              padding: 0 0.5rem;
              text-transform: uppercase;
              border: 1px solid $border-clr;
            }
          }
        }
      }

      .links-text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
        .print-title {
          margin-bottom: -0.5rem;
        }
      }

      .designBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin: 1rem 0;
        border-radius: 0.563rem;
        -webkit-border-radius: 0.563rem;
        -moz-border-radius: 0.563rem;
        -ms-border-radius: 0.563rem;
        -o-border-radius: 0.563rem;
      }

      .btns-container {
        margin: 1.5rem 0;
        width: fit-content;
        border-bottom: 1px solid $skyblue-clr6;
      }

      .product-info-card {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: center;
        justify-content: center;
        height: 10.625rem;
        border-radius: 1rem;
        background-color: #f5f5f5;
        border: 0px solid $border-clr;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        img {
          object-fit: contain;
          height: 2.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .discount {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .additional-information-container {
    padding: 6rem;
    background-color: $offwhite-clr2;

    .additional-information {
      margin-top: 1rem;
      margin-bottom: 3rem;
      li {
        list-style-position: inside;
        list-style-type: disc;
        margin-bottom: 0.3rem;
        * {
          font-weight: 400 !important;
        }
        .description {
          min-width: 10rem;
          display: inline-block;
        }
      }
    }

    .additional-sizes {
      margin-bottom: 2rem;
      .description {
        font-weight: 400 !important;
        line-height: 3.125rem;
      }
    }

    .qoute-calc {
      .openQouteCalc {
        margin-top: 1rem;
      }
    }

    .accordion {
      * {
        background-color: transparent !important;
      }
      .ant-collapse .ant-collapse-item .ant-collapse-header {
        padding: 0.5rem 1rem;
        img {
          width: 1rem;
        }
      }
    }
  }
}
img.top-icon-product-pge {
  width: 22%;
  /* border: 1px solid; */
  margin-bottom: 20px;
}
.description ul li {
  list-style-type: disc !important;
}