.breadcrumb-nav {
    font-size: 14px;
    margin-bottom: 30px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #aaaaaa;
    padding-top: 43px;
}

.breadcrumb-link {
  color: #29b8e3;
  text-decoration: none;
}

.breadcrumb-link.active {
  color: #555;
  font-weight: bold;
}

.breadcrumb-link::after {
  content: " / ";
  color: #aaa;
}

.breadcrumb-link.active::after {
  content: "";
}
