

.productsWithCategories {
  padding: 4rem 0px;
  .title-section {
    text-align: center;
    .sub-title {
      margin-top: 0.5rem;
    }
  }

  .category-listing {
    li {
      margin-bottom: 0.5rem;
      .view-all-btn {
        text-decoration: underline;
      }
    }
  }

  .page-header {
    margin-bottom: 3rem;
  }

  .header-row {
    padding: 1rem 0.5rem;
    margin: 1rem 0;
    border-top: 1px solid $border-clr2;
    border-bottom: 1px solid $border-clr2;
    > div {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width:100%;
    }
    > div:first-child {
      border-right: 1px solid $border-clr2;
    }
  }
  .search-container {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    gap: 2rem;
    .title {
      width: 30%;
    }
  }
  .sorting-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    .image-container {
      display: flex;
      align-items: baseline;
      .sort-icon {
        height: 0.813rem;
        margin-right: 0.5rem;
      }
    }
  }

  .filter-content {
    padding: 0 0.5rem;
    .filters {
      border-right: 1px solid $border-clr2;
    }
    .productListing {
      padding: 4rem 0 4rem 1.5rem;
    }
  }
}


ul.category-listing.categorypage-main h5.title.undefined.fs-17.text-undefined.fw-400 {
  padding: 0 !important;
  font-size: 14px;
  color: #acacac;
  cursor: pointer;
}
ul.category-listing.categorypage-main h5.title.undefined.fs-17.text-undefined.fw-400:hover {

  color: #1cc0d9;

}
.categorypage-main-cls .ant-collapse-content-box {
  padding: 0 !important;
}
ul.category-listing.categorypage-main {
  padding: 0 !important;
}