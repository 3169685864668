*, *::before, *::after {
  box-sizing: unset;


}
img{
  user-select: none !important;
}
.row-wesite-header {
  padding: 0px 0px !important;
}
.size-productcolor h4 {
  font-size: 14px;
}
.size-productcolor p {
  font-size: 14px;
  margin-top: 8px;
} 
.size-productcolor {
  margin-top: 24px;
}

div#initial-loader {
    display: none;
}
.toggle-icon img {
  width: 18px;
}
.row-table-pricing{
width: 100% !important;
max-width: 100% !important;
flex-flow: unset !important;
justify-content: space-between !important;
}
table.table-prcing {
  width: 80%;
  border: 1px solid #ecf0f1;
  margin: auto;
}
p.price-sidebar-pricing .unit {
  color: #1cbed9;
  font-size: 22px;
  vertical-align: middle;
  font-weight: 700;

}
.toggle-tabs-btn {
  display: none;
}
p.price-sidebar-pricingss {
  background: #f2dede;
  color: #a94442;
  padding: 15px 12px;
  font-size: 14px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 13px;
  border-radius: 5px;
  border: 1px solid #ebccd1;
}

.printing-typeconstant {
  display: none !important;
}
.loader-container.loader-main-index {
  position: relative;
  top: 38%;
}
.table-prcing {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.table-prcing .faster-options {
  background: #f5f5f5;
  padding: 28px;
  width: 100%;
  text-align: center;
  color: #767676;
  font-size: 12px;
  font-weight: 400;
}
.standard-delivery p img {
  width: 12px;
}
.faster-options span img {
  width: 12px;
}
.price-sidebar-content.price-sidebar-content-for-price .main-img {
  margin: 0;
}
h5.your-order-name {
  background: #1cbed9;
  padding: 4px;
  color: white;
  margin-top: 19px;
}
.table-prcing .standard-delivery {
  background: #f5f5f5;
  padding: 28px;
  width: 100%;
  text-align: center;
  color: #767676;
  font-size: 12px;
    font-weight: 400;
}

p.price-sidebar-pricing .total-pricey {
  color: #1cbed9;
  font-weight: bold;

}
p.price-sidebar-pricing {
  background: #f5f5f5;
  color: #767676;
  padding: 8px 12px;
  font-size: 12px;
}


.main-pricebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}









.toggle-box {
  display: flex
;
  align-items: center;
  bottom: 9%;
  position: absolute;
  left: 37%;
}
.toggle-icon {
  border: 1px solid #808080;
  padding: 4px;
  border-radius: 6px;
}

.toggle-text-container {
  overflow: hidden;
  max-height: 0; /* Initially hidden */
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out; /* Smooth sliding */
  padding: 0; /* Initially no padding */
}

.toggle-text-container.open {
  max-height: 100px; /* Adjust to the height of your content */
}

.toggle-text-container.closed {
  max-height: 0; /* Collapse */
  padding: 0; /* Remove padding */
}

.toggle-text {
  display: flex
;
  align-items: center;
  background-color: #ffffff5e;
  padding: 5px 10px;
  border-radius: 3px;
}
.close-toggle {
  color: #ff0000;
  font-weight: bold;
  margin-left: 10px;
}


.text-pricebartagline {
  display: none;
}
/* .text-pricebartagline p {
  background: #ecf0f1;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
} */
table.table-prcing td {
  border: 1px solid #ecf0f1;
  padding: 9px 9px;
  /* display: flex; */
}

/* .main.main-sidebar-visible {
  width: 100% !important;
} */


.tabs button.active {

pointer-events: none;
user-select: none;
}

.color-picker {
  width: 100%;
  height: 93%;
  top: 29px;
  left: 0px;
  box-sizing: border-box;
  border: 0px !important;

  overflow: hidden;
  margin-top: 0px !important;
}


.top-nav a{

  text-decoration: none;
}




.tipOfTheDay {
  display: none !important;
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;

}button.back-btn.closebtn-pricesidebar {
  background: transparent;
  border: 0;
  margin-top: 3px;
}
button.back-btn.closebtn-pricesidebar img {
  height: 16px;
  cursor: pointer;
}
button.close-btn img {
height: 15px;
cursor: pointer;

}
button.click-here {
  background: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
}
/*maaz*/
.gildan{
    margin: 0 auto;
}
.label-input-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-input-pair label {
  display: block;
  margin-bottom: 5px;
  text-align: center;
}

.label-input-pair input {
  text-align: center;
  padding: 5px;
}
.size-inputs {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}
.adult-sizes input[type="number"] {
  width: 25px;
  padding: 5px;
  margin-top: 5px;
}
.size-mainss{
    width: 50%;
    display: flex;
    justify-content: space-around;

}


.main {
  width: 60%;
  padding: 0px !important;
  overflow: hidden;
}

.main-img {
    display: flex;
    width: 100%;
    border: 1px solid #ecf0f1;
    margin-top: 22px;
    padding: 12px;
    box-sizing: border-box;
}

/*maaz*/



.main-cls{



  background-color: #f0f0f0;
}
.tabs button.active {
    border:3px solid #1CBED9;
    border-radius: 4px;

}
.tabs button{
        border: 0;
    padding: 5px 13px;
    border: 1px solid;
    display: block;

}

.mobile-tabs-container + .constant-sidebar {
  display: none;
}
.mobile-tabs-container ~ .constant-sidebar {
  display: none;
}
.mobile-tabs-container:has(+ .constant-sidebar) + .constant-sidebar {
  display: none;
}


.tabs button:hover{
    filter: brightness(.9);

}
.tabs button {
  background: #ecf0f1 !important;
    border: 0;
    padding: 0px 0px;
    border: 1px solid;
    display: block;
    background: transparent;
    border: x;
    border: 0;
    cursor: grabbing;
    border:3px solid white;
    margin-top: 6px;
}
button.active {
    background: #F5F6F7;
}
.container-tool.main-cls {
  display: flex;
  padding: 0px 12px !important;
}
/*.footer {
    margin-top: 53px !important;
}*/

.st0 {
   
    fill: #e2e2e2 !important;
}
.option:hover {
    color: #434d60;
}

.option.change svg {
    width: 60% !important;
}
.side-main{


     display: grid;
    grid-template-columns: auto auto;
    gap: 18px;
    justify-content: center;
}
.sidebar {
  width: 20%;
  background-color: #2c3e50;
  padding: 20px;
  color: #000;
  }
.sidebar .option {
    background-color: transparent;
    margin: 0px 0;
    padding: 26px 21px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-size: 9px;
    width: 84px;
    height: 84px;
    vertical-align: middle;
    align-items: center;
    align-content: center;
}
.main {
  width: 60%;
  padding: 20px;
}
.option.changeproduct svg {
    width: 45% !important;
}
.main .header-tool {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.main .header-tool .contact-info {
  display: flex;
  align-items: center;
}
.main .header-tool .contact-info span {
  margin-right: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}
.footer button {
    padding: 16px 20px;
    background-color: transparent;
    color: gray;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
}
.svg-display {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}
.option svg {
    width: 47%;
    margin: 5px 0px;
}

.uploading-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  /* High z-index to ensure it appears above all other content */
  user-select: none;
}

.uploading-popup-content {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.uploading-popup-content p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
.upload-spinner-text{

  color: white;
  font-size: 16px;
  text-align: center;
}






/* App.css */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Content {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
}
h2.tool-text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 16px 0px !important;
  color: #838889;
  letter-spacing: 1px;
}

.emb-text{


  text-transform: capitalize;
  color: #1cbed9;

}

.printing-type-text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  /* margin: 0 0px !important; */
  color: #838889;
  letter-spacing: 1px;
  margin-top: 32px;
  margin-bottom: 10px !important;
}
/* .clipart-component {
  margin-top: 20px;
} */

.clipart-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.clipart-thumbnail {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.color-palette {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid #f5f5f5; /* Black border */
  border-top-color: #1cc0d9; /* Red top border */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.color-palette .color-box {
  width: 25px !important;
  height: 25px !important;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px !important;
  cursor: pointer;
}

.align-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.label-input-pair input::placeholder{

  color: hwb(0 11% 87% / 0.114);
}
input#zipCode::placeholder{

  color: hwb(0 11% 87% / 0.114);
}
.align-buttons button {
  margin-bottom: 5px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.tab-content.main-sidebar-visible-tab canvas#canvasTwo {
  visibility: hidden;
}
.container-tool.main-cls {
    display: flex;
    height: 77vh;
    position: relative;
}
.export-section {
  display: none;
}

.canvas-container {
    width: 100% !important;
    height:100% !important;
    position: relative;
}
.main {
    width: 64%;
    padding: 34px;
    height: 100%;
}
.canvas-area {
    width: 100%;
    height: 100%;
}
.design-area {
    margin: 20px 0;
    /* object-fit: cover; */
    width: 100%;
    height:100% !important;
}
.tab-content {
    height: 100%;
}
.abd{
    height: 100%;
}
canvas{
    height: 100% !important;
    width: 100% !important;

}

.constant-sidebar {
    width: 6%;
    background-color: #202B40;
    padding-top: 25px;
    margin: 23px 0px;
}
.texticon svg {
    width: 65% !important;
}
.side-main .option .st0 {
    fill: #1cbed9 !important;
}

.side-main .option:hover .st0{
    fill: #1CBED9 !important;
}
.side-main .option:hover {
   
    color: #1CBED9 !important;
}
.side-main .option {
   
    color: #a8adb5 !important;
}
.side-main .option:hover:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background: #1CBED9;
    transition: .8s;

    border-radius: 80px;
    top: 15px;
    right: 12px;
}


.side-main .option{
   position: relative;
   transition: .5;
}

.sidebar {
  width: 30%;
/*  border-left: 0 !important;*/
  background-color: #fff;
  border: 1px solid #aaaa;
/*  padding: 20px;*/
  margin: 23px 0px;
  border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
        height: auto;
        overflow: auto;
        position: relative;
}

.main {
  flex-grow: unset;
  padding: 20px;
  background-color: #ecf0f1;
box-sizing: border-box;


}


img.img-side-bar {
    width: 100%;
}

.option img {
    width: 47%;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 10px;
    display: table;
}
.option {
    cursor: pointer;
    padding: 10px 3px;
    border-bottom: 1px solid #434d60;
    margin-bottom: 10px;
    color: white;
    font-size: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.side-main .option {
    border: 1px solid #c3c7c8 !important;
    color: #202b40;
}


 .option.selected {
  background-color: #fff;
  color: black;
  pointer-events: none;
}
 .option.selected .st0{
  fill:#202B40 !important;
}
.option.selected .fil00{
  fill:#202B40 !important;
}



.constant-sidebar::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.constant-sidebar::-webkit-scrollbar-thumb {
  background-color: #888; /* Change to your preferred color */
  border-radius: 10px;
}

.constant-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.constant-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change to match your design */
}
.constant-sidebar{

  overflow: auto;
}

.option:hover {
    background-color: #b4b4b43b;
}
/*.option:hover .st0 {

  fill:#202B40 !important;
}*/


.option:hover{

  color:#fff;
}
.header-tool, .footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.design-area {
  margin: 20px 0;
}
.tabs {
    float: right;
    margin-top: 65px !important;
    z-index: 1;

    position: absolute  !important;
     right: 12px;
}
.undo-redo-buttons {
    position: absolute;
    top: 5%;
    left: 10px;
}.canvas-area {
 
    position: relative;
}
button.undobtn {
/*    background: #ffffffd1;*/
    border: 0;
    margin: 2px;
cursor: pointer;            padding: 0 !important;
background: transparent !important;
}

button.undobtn svg {
    padding:0px;
    width: 50px;
    height: 50px;

}
.undo-redo-buttons button.undobtn svg path {
  fill: #202B40; /* Normal fill color */
  width: 40px !important;
  height: 40px !important;
}

.undo-redo-buttons button.undobtn svg path:hover {
  fill: #1CBED9; /* Normal fill color */

}
.undo-redo-buttons button.undobtn:disabled svg path {
  fill: #d3d3d3; /* Different color for disabled state */
}

.undo-redo-buttons button.undobtn:disabled {
  cursor: pointer; /* Optional: change cursor to indicate disabled state */
}
.tabs {
    float: right;
    margin-top: 7%;
    z-index: 1;
    position: relative;
    padding: 0;
    background: transparent;
    border-radius: 14px;
    box-sizing: border-box;
}
button.redobtn {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.product-ui {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.add-products {
    flex: .7 1;
}
.add-products-button {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}
.product-details {
    display: flex;
    align-items: center;
    flex: 3 1;
}


.product-image img {
  width: auto;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-info {
    margin-left: 5px;
    border-left: 1px solid #a8adb5;
    padding-left: 5px;
}/* PriceSidebar.css */
.price-sidebar {
    position: absolute;
    right: 12px;
    top: 0;
    width: 40%;
    /* height: 100%; */
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
}


.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  float: right;
}

.price-details {
  margin-top: 20px;
}
.price-sidebar-content {
    padding: 4px 12px;
}

.continue-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}


.label {
  font-weight: bold;
}

.change-link {
  margin-left: 10px;
  color: #007bff;
  cursor: pointer;
}
.total-Quantity label input[type="number"] {
    border: 0;
    font-size: 19px;
    vertical-align: middle;
        outline: none;
    width: 50%;
}
.total-Quantity label {
    font-size: 16px;
    align-content: center;
}
.main-img p a{


        color: #1cbed9;
}
.actions {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-end;
}
.save-share-button, .get-price-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  border-radius: 5px;
}

.save-share-button {
  background-color: #f2f2f2;
  color: #000;
  border: 1px solid #ccc;
}
button.get-price-button {
    background: #1CBED9;
    color: white;
    border: 0;
}
.footer button:hover {
       background: #1CBED9;
    color: white;
      border: 0;
}

span.change-colors {
    color: #007bff;
    padding-left: 11px;
    cursor: pointer;
}
span.color-code {
    display: none;
}
.tabs button img {
    display: block;
    width: auto;
    height: 60px;
}
button.zoom {
    text-align: center !important;
    width: 100%;
    margin-top: 20px;
}
/*.size-input input[type="number"] {
    width: 97%;
    padding: 9px;
    box-sizing: border-box;
}
.sizes {
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.price-preview {
    width: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    right: 0;
    z-index: 9999999999999999999999999;
    padding: 25px;
}*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.startover {
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-top: 60px;
    background: transparent;
    color: #a8adb5;
    border: 0;
    font-size: 16px;
    cursor: pointer;
    display: none;
}
button.startover:hover {
  
    color: #1cbed9;
 
}
/* .main-sidebar-visible {
  max-width: 100%;
  
} */
.container-tool.main-cls {
    position: relative;
}
.undo-redo-buttons-visible {
    display: none;
}
.tab-content {
  width: 100% !important;
  height: 100%;
}


/*sdfs*/






.close-btn {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/*.size-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
*/
.youth-sizes, .adult-sizes {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.youth-sizes input[type="number"] {
  width: 25px;
  padding: 5px;
  margin-top: 5px;
}

.price-details {
  margin-top: 20px;
  width: 100%;
}



/*sdfd*/


.price-sidebar.show {
  transform: translateX(0);
}


.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  float: right;
}

.product-header {
  display: flex;
  align-items: center;
}

.product-image {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.product-info {
  flex-grow: 1;
}

.product-info h3 {
  margin: 0;
  font-size: 16px;
}

.product-info p {
  margin: 5px 0;
}

.add-color, .sizing-guide {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.sizes-section {
  margin: 20px 0;
}

.sizes-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sizes-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.size-box {
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.price-details {
  margin: 20px 0;
}

.continue-btn {
    width: 100%;
    padding: 10px 59px;
    background: #1cbed9;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
}
.size-box input[type="number"] {
    width: 100%;
    height: 31px;
    text-align: center;
}.main-img h3, p {
    margin: 0;
}


.price-sidebar {
  width: 40%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
}
.closebtnaaa {
    display: inline-table;
    width: 100%;
    background: #1cbed9;
    padding: 7px;
    border: 1px solid #e3dede;
    box-sizing: border-box;
}
.size-inputs {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.label-input-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-input-pair label {
  display: block;
  text-align: center;
  font-size: 12px;
}


  .label-input-pair input {
    text-align: center;
    padding: 5px;
    width: 40px;
    height: 20px;
    margin-left: 0px;
    border: 1px solid #cfd2d3;
    outline: none;
    border-radius: 4px;
    font-size: 18px;
}.size-mainss p {
    font-weight: bold;
    font-size: 12px;

}

/* Container for the entire design save step */
.unique-save-cart-container {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 30px;
}

.mapimage {
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 12px !important;
  margin-bottom: 12px;
}

/* Title and description */
.save-cart-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.save-cart-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.flex-buttonprice {
  display: flex;
  justify-content: space-between;
}
/* Input group for design name and email */
.input-group-extreme {
  margin-bottom: 20px;
  text-align: left;
  width: 48%;
}

.input-label-extreme {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}

.styled-input-extreme {
  width: 100%;
  padding: 12px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.styled-input-extreme:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input-helper-text-extreme {
  font-size: 12px;
  color: #888;
}

/* Terms and privacy policy text */
.terms-conditions-text {
  font-size: 12px;
  color: #555;
  margin-bottom: 20px;
}

.privacy-policy-link {
  color: #007bff;
  text-decoration: underline;
}

.privacy-policy-link:hover {
  color: #0056b3;
}

/* Save and Add to Cart button */
.save-cart-button-extreme {
  background-color: #007bff;
  color: white;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 30px;
}

.save-cart-button-extreme:hover {
  background-color: #0056b3;
}

/* Shipping info section */
.shipping-info-extreme {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.shipping-image-extreme {
  width: 20%;
  margin-right: 20px;
}

.shipping-text-extreme {
  text-align: left;
}

.ship-to-everyone-extreme {
  color: #ff4500;
  font-weight: bold;
  font-size: 16px;
}

.get-your-gear-extreme {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.shipping-details-extreme {
  font-size: 14px;
  color: #555;
}

.input-group-extreme input {
  box-sizing: border-box;
  font-size: 18px;
}
.total-Quantity {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    background: white;
    padding: 13px 11px;
    box-shadow: 0px 0px 22px #aaaaaa42;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}

/* Container for the entire step */
.shipping-step {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
}

/* Title style */
.shipping-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.weareshipping {

  width: 60%;
  font-size: 12px;
  margin: 0 auto;
}

/* Input group with unique styling */
.input-group-unique {
  position: relative;
  margin-bottom: 15px;
}

.styled-input-unique {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.styled-input-unique:focus {
  border-color: #007bff;
  outline: none;
}

/* Link for users outside US/Canada */
.outside-link {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
}
.continue-btnzip {
  width: 40%;
  padding: 10px 25px;
  background: #1cbed9;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
}

input#zipCode {
  width: 40% !important;
  box-sizing: border-box;
  font-size: 18px;
  margin-top: 16px;
  text-transform: uppercase;
}
/* Button for seeing the all-inclusive price */
.see-price-btn {
  background-color: #007bff;
  color: white;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin: 20px 0;
  box-sizing: border-box;
}

.see-price-btn:hover {
  background-color: #0056b3;
}

/* Shipping info section */
.shipping-info {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.clipart-subcategories {
  display: grid;
  grid-template-columns: auto auto;
  gap: 13px;
  margin-top: 18px;
}
.shipping-image {
  width: 30%;
  margin-right: 20px;
}

.shipping-text {
  text-align: left;
}

.ship-to-everyone {
  color: #ff4500;
  font-weight: bold;
  font-size: 16px;
}

.get-your-gear {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.shipping-details, .taxes-info {
  font-size: 14px;
  color: #555;
}
.size-mainss  {
margin-top:8px; 
}

.labels {
  display: flex;
}

.canvas-area-none {
    pointer-events: none;
}

button.get-price-button span {
    vertical-align: middle;
}

button.get-price-button span img {
 padding-right: 12px;
}

button.save-share-button span {
    vertical-align: middle;
}

button.save-share-button span img {
  padding-right: 12px;
}
/* arc */

.warp-type-container {
  margin-bottom: 10px;
}

.warp-type-options {
  display: flex;
  flex-wrap: wrap;
}

.warp-type-option {
  flex: 1 1 30%;
  margin: 0px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

.warp-type-option.selected {
  border: 3px solid #1cc0d9;
}
.warp-type-option:hover {
  background: #f0f0f0;
}
.warp-type-option label {
  display: block;
  cursor: pointer;
} 
.tabs {
display: block;
}
@media (max-width: 1200px) {


.main {
  flex-grow: 1;


}
.inner.distort-remove-cls {
  display: none;
}
.logo-colomn-mobile {
  padding-left: 0 !important;
}

  .tabs.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  img.sideBarIcon {
    width: 60%;
    height: auto;
}

}

@media (min-width: 1200px) {
 
}


@media only screen and (max-width: 900px) {

  .footer-tool-page {
    display: none;
  }
  .size-inputs {

    grid-template-columns: repeat(5, 1fr);

  }
  .constant-sidebar .option {
    border-left: 1px solid #8080808f;
    border-radius: 0;
}
.constant-sidebar .option div svg {
  width: 100% !important;
  /* border-left: 1px solid; */
}
  .option {
    cursor: pointer;
    padding: 0;
    border-bottom: 1px solid #434d60;
    margin-bottom: 0;
    color: white;
    font-size: 10px;
    padding-bottom: 0;
    text-align: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 16.66%;
    box-sizing: border-box;
    box-shadow: none;
    border: 0;
}
  .price-sidebar {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    position: absolute;
    top: 0;
    right:0; 
}
  .tab-mobile-content {
    padding: 12px 24px;
    max-height: 100%;
    height: auto;
    overflow: auto;
}
      .sidebar {
          width: 100%;
          /* border-left: 0 !important; */
          background-color: #fff;
          border: 1px solid #aaaa;
          padding: 0px;
          /* margin: 23px 0px; */
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          height: auto;
          overflow: auto;
          position: relative;
          position: absolute;
          z-index: 2;
          bottom: -25px;
          z-index: 1;
      }
      .constant-sidebar {
          width: 100%;
          position: absolute;
          background-color: #202b40bf;
          padding-top: 0px;
          margin: 0px 0px;
          bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          z-index: 1;
          padding: 2px 10px;
          padding-bottom: 6px;
          box-sizing: border-box;
      }
      .font-options-container, .arc-controls-container, .outline-controls-container, .letter-spacing-container {
    position: relative; /* or absolute depending on your layout */
    top: -50px; /* Example: Move the toggle content above */
  }
  
  .font-options, .arc-controls, .outline-controls, .letter-spacing-controls {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    display: block; /* Or flex based on your preference */
  }
  .tabs-mobile {
    background: #0000005c;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }
  .tabs-mobile button.tab-button {
    background: transparent;
    border: 1px solid gray;
    padding: 11px;
    box-sizing: border-box;
  }
  .grid-cls {
    display: grid;
    padding: 12px;
  }
  .grid-cls input#text_value {
    padding: 10px;
    margin-bottom: 18px;
  }

  .sidebar .side-main, .tool-text  {
    display: none;
  }
  button.startover{

    display: none;
  }
  .mobile-tabs-container .tab-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    scrollbar-width: none; /* For Firefox */
  }
  .container-tool.main-cls {
padding: 0px !important;
}


.printing-typeconstant {
  display: block !important;
}
.footer-tool {
  display: none;
}
  
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  .mobile-tabs-container .tab-list::-webkit-scrollbar {
    display: none;
  }
  
  /* For Internet Explorer and Edge */
  .mobile-tabs-container .tab-list {
    -ms-overflow-style: none;  /* Hide scrollbar in IE/Edge */
  }
  
  .mobile-tabs-container .tab-list li {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    flex-shrink: 0;
  }
  .container-tool.main-cls {
    display: flex;
    height:  calc(var(--vh, 1vh) * 90 - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    width: 100%;
    overflow: hidden;
}
.printing-type {
  display: none !important;
}
  
  }

  @media only screen and (max-width: 900px) {

    .container{
      padding:0px;


    }

    input#zipCode {
      width: 60% !important;
      box-sizing: border-box;
      font-size: 18px;
      margin-top: 16px;
      text-transform: uppercase;
    }
    .row-wesite-header{
      padding: 12px 10px !important;
    }
    button.button-to-text.display-none-mobile {
      display: none;
  }
  .display-none-mobile{
    display: none;
  }

  .productsWithCategories .filter-btn{
    box-sizing: border-box;
    vertical-align: middle;
  }
  .toggle-box {
    display: flex
;
    align-items: center;
    bottom: unset;
    position: absolute;
    left: 50%;
    top: 14px;
}
    .toggle-text-container {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
        padding: 0;
        position: fixed;
        left: 50%;
        top: 19%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1000;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        box-sizing: border-box;
    }

.toggle-text-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    padding: 0;
}

.toggle-text-container.open {
  max-height: 200px; /* Adjust to the height of your content */
  padding: 20px; /* Add padding when open */
}

.toggle-text-container.closed {
  max-height: 0; /* Collapse */
  padding: 0; /* Remove padding */
}

.toggle-text {
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the content vertically */
  text-align: center; /* Center the text inside the container */
  padding: 10px;
}


  }

  @media only screen and (max-width: 992px) {
 

    .size-inputs {
      grid-gap: 10px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(5, 1fr);
      
    }
    .tabs {
      float: right;
      margin-top: 7%;
      z-index: 1;
      position: relative;
      padding: 0;
      background: transparent;
      border-radius: 14px;
      box-sizing: border-box;
      height: 62%;
      overflow: auto;
      border-radius: 0;
  }
    .toggle-tabs-btn {
      display: block;
      position: fixed;
      top: 12%;
      right: 11px;
      z-index: 1;
      width: 30px;
      height: 30px;
      background: transparent;
      border: 1px solid #8080804d;
      padding: 7px;
  }
  .toggle-tabs-btn img {
    width: 100% !important;
}
.option.selected {
  background-color: #fff;
  color: black;
  pointer-events: auto !important;
  border-radius: 4px !important;
  padding: 0px 12px;
  padding-bottom: 4px;
  box-sizing: border-box;
}
    /* .price-sidebar-content .main-img .first-img {
      width: 12% !important;
      margin-right: 12px;
  }
  .price-sidebar-content .main-img .second-content{
    width: 88% !important;
} */
  }
  @media only screen and (max-width: 767px) {

    img.sideBarIcon {
      width: 80%;
      height: auto;
  }
  }

   
   
/*.canvas-hidden {
  display: none;
}*/
  