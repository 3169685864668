.displayimageai{

    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: table;
}
.thumbnailaiimage{
    width: 20px;
    height: 20px;
}

.text-aiprompt {
    width: 96%;


    margin: 0 auto;
    text-align: center;
    display: table;

    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    border: 1px solid rgba(170, 170, 170, 0.667);
    box-sizing: border-box;
    margin-top: 20px;
}
.text-aiprompt:focus{


    outline:1px solid #1cbed9;
    border: 0px;

}
.button-generate-aiimage {
    align-items: center;
    vertical-align: middle;
    margin-top: 10px !important;
    padding: 10px 20px;
    width: 60%;
    background-color: rgb(28, 190, 217);
    box-shadow: none;
    color: white;
    border: none;
    border-radius: 7px;
    text-align: center;
    margin: 0 auto;
    display: table;
    font-weight: 600;
    cursor: pointer;
    font-size: 20px;
}
.button-generate-aiimage img{
    width: 20px;
    padding-right: 3px;
    vertical-align: middle;
    margin-top: -5px;
}

.ai-images-main {
    display: grid
;
    grid-template-columns: 33.33% 33.33% 33.33%;
    gap: 10px;
    padding: 0px 10px;
}
