.payment-confirm-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}


td.account-preview {
  display: grid;
  grid-template-columns: auto auto;
}
.payment-confirm-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.sidebar-account-page ul.side-menu li img {
  padding-right: 9px;
}
.payment-confirm-header {
  background-color: #eeeeeea8;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.payment-confirm-header h5 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.payment-confirm-details {
  padding: 2rem;
  font-size: 1rem;
}

.payment-confirm-shipping-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  font-weight: 500;
}

.payment-confirm-shipping-address {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
}
.accountnamefirst{
text-transform: capitalize;

}
.payment-confirm-status {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 12px;
  font-weight: 500;
  flex-wrap: wrap;
}

.order-placed-status,
.order-processing-status,
.order-shipped-status,
.order-delivered-status {
  font-weight: 600;
}

.order-placed-status.completed {
  color: green;
}

.status-separator {
  width: 30px;
  height: 2px;
  background-color: #ccc;
  margin: 0 10px;
}

.payment-confirm-product {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: 500;
}

.payment-confirm-product img {
  margin-right: 1rem;
  width: 60px;
}
.main-invoice .button-container {
  max-width: 575px;
  width: 90%;
  /* float: right; */
  margin: 0px auto;
  text-align: right;
  margin-top: 27px;
}
.main-invoice .button-container button{

  background: #1cc0d9;
  border: 0;
  color: #ffffff;
  border-radius: 14px;
  padding: 13px 16px;
  box-sizing: border-box;
 margin: 0px 5px;
  cursor: pointer;
  border: 1px solid;
  font-size: 16px;
}
.popup-actions {
  margin-top: 31px;
}

.logout-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding: 60px;
}

.popup-actions button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #1cc0d9;
  color: white;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
}

.main-invoice .button-container button:hover{

  background-color:   white;
  color: #1cc0d9;
}
.payment-confirm-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 500;
  flex-wrap: wrap;
}

.payment-confirm-summary td {
  font-size: 12px;
  font-weight: 400;
}

.payment-confirm-payment-type {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-top: 2rem;
}

.payment-confirm-footer {
  background-color: #eeeeeea8;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  flex-wrap: wrap;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.btn-link:hover {
  text-decoration: underline;
}

.text-success {
  color: green;
}

.font-weight-bold {
  font-weight: 600;
}

.table-borderless td,
.table-borderless th {
  border: 0;
  font-size: 12px;
}

.text-right {
  text-align: right;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .payment-confirm-header {
      flex-direction: column;
  }

  .payment-confirm-shipping-info {
      flex-direction: column;
      text-align: left;
  }

  .payment-confirm-summary {
      flex-direction: column;
      text-align: left;
  }

  .payment-confirm-status {
      flex-direction: column;
  }
}

@media (max-width: 576px) {
  .payment-confirm-container {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }

  .payment-confirm-details {
      padding: 1rem;
  }

  .payment-confirm-shipping-address,
  .payment-confirm-summary,
  .payment-confirm-payment-type {
      font-size: 10px;
  }

  .payment-confirm-footer {
      font-size: 10px;
      padding: 0.5rem;
  }

  .payment-confirm-product img {
      width: 40px;
  }
}


/* Add this to your CSS file or within a <style> tag */
.table-container {
  overflow-x: auto;

}
button.seeinvoices {
  background: transparent;
  border: 0;
  color: #1f1f2b;
  border-radius: 14px;
  padding: 4px 9px;
  box-sizing: border-box;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid;
}
button.seeinvoices:hover{

  background-color: #1f1f2b;
  color: white;
}
.styled-table {
  width: 100%;
  border-collapse: separate;
  font-size: 16px;
  text-align: left;
  margin: 0 auto;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  border-spacing: 0px 15px;
}

.styled-table thead tr {
  background-color: #1f1f2b;
  color: white;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  /* border: 1px solid #ddd; */
  font-size: 10px;
  row-gap: 5px;
}
.myaccount-page {
  background-color: #f5f5f5;
  padding: 0px 19px;
}
.myaccount-container{

  max-width: 1700px !important;
  width: 100% !important;
  margin: 0 auto;
  box-sizing: border-box;
}
.styled-table tbody tr {
  background-color: #ffffff;
}

/* .styled-table tbody tr:hover {
  background-color: #f1f1f1;
} */

.styled-table img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 5px;
}

.styled-table td img {
  display: inline-block;
}

.styled-table th {
  text-align: center;
}


.account-form {
  display: flex;
  flex-direction: column;
  
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-btn {
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}
td.status-account {
  color: #1cc0d9;
  font-weight: 400;
  font-size: 16px;
}

.update-btn {
  background: #1cc0d9;
  width: 100%;
  box-sizing: border-box;}
  .property {
    display: flex
;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    padding-bottom: 65px;
    /* margin-top: 65px; */
}
.sidebar-account-page ul.side-menu {
  padding: 0;
  margin-top: 34px;
}

.sidebar-account-page {
  width: 21%;
  background: #ffffff;
  height: 100vh;
  padding: 0px 8px;
  box-sizing: border-box;
  padding-top: 35px !important;
}
.content-account {
  width: 77%;
  padding-top: 35px;
}
.sidebar-account-page ul.side-menu li:hover{

  background-color: #f5f5f5;
  border-radius: 12px;
}

.sidebar-account-page ul.side-menu li {
  /* background: #f5f5f5; */
  margin-bottom: 6px;
  padding: 8px;
  box-sizing: border-box;
  color: #1f1f2b;
  font-size: 16px;
  /* margin-right: 38px; */
  /* border-top: 1px solid #e5e3e3; */
  padding-top: 10px;
  cursor: pointer;
}
h1.welcomeback-tag{
  display: flex
  ;
      justify-content: space-between;
      width: 100%;
      align-items: center;

}
.img-welometag{

  margin-right: 15px;
}

ul.side-menu li.active {
  /* background: #1cc0d9; */
  color: white;
background-color: #1f1f2b;
pointer-events: none;
user-select: none;
border-radius: 12px;

}
ul.side-menu li.active a {

  color: white !important;
  text-decoration: none;
}
/* .myaccount-page {
  margin: 5rem auto;
} */
.mobile-select select {
  width: 100%;
  display: none;
}

@media screen and (max-width:1024px) {

  h1.welcomeback-tag {
    display: flex
;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    text-align: center;
}
}
@media screen and (max-width:900px) {
  .sidebar-account-page {
  display: none;
}
  
.content-account {
  width: 100%;
}
.mobile-select select {
  display: block;
  padding: 14px;
  margin-top: 22px;
  margin-bottom: 11px;
  border: 1px solid #f5f5f5;
  width: 100%;
  box-sizing: border-box;
}

}