/* App Layout */

.inkox-content-h1{
  font-size: 24px;
}

.inkox-content-h2{
  font-size: 20px;
}

.inkox-content-h2{
  font-size: 18px;
}

.inkox-content-para{
  font-size: 14px;
}



.inkox-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
}

/* .inkox-sidebar h2 {
  margin-bottom: 15px;
}

.inkox-sidebar ul {
  list-style: none;
  padding: 0;
}

.inkox-sidebar li {
  margin-bottom: 10px;
}

.inkox-sidebar a {
  text-decoration: none;
  color: #333;
} */

.inkox-main2 {
  width: 80%;
  display: flex;
  margin: 0 auto;
}

.inkox-undermain {
  width: 25%;
}

.inkox-browsebut {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.inkox-primary-button:hover {
  background-color: #29b8e3;
}

.inkox-divider {
  width: 80%;
  margin: 0 auto;
}

/* Sidebar Styling */
/* .inkox-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f1f1f1;
}

.inkox-sidebar h3,
.inkox-sidebar h4 {
  margin-top: 0;
}

.inkox-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.inkox-sidebar ul li {
  margin: 10px 0;
}

.inkox-sidebar {
  width: 26% !important;
} */

/* Main Content Styling */
.inkox-main-content {
  
  max-width: 100% !important;
  padding: 0px !important;
}

.inkox-main-content h1 {
  font-size: 24px;
  margin-top: 0;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.6;
}

.inkox-image-section {
  margin-top: 20px;
}

.inkox-image-section img {
  max-width: 100%;
  height: auto;
}

/* Main container holding both sidebar and main content */
.inkox-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  width: 96%;
  margin: 0 auto;
}

/* .inkox-sidebar h3, .inkox-sidebar h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.inkox-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.inkox-sidebar ul li {
  margin-bottom: 10px;
  font-size: 15px;
  color: #0073e6;
  cursor: pointer;
}

.inkox-sidebar ul li:hover {
  text-decoration: underline;
} */

/* Main content area */


.inkox-main-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.inkox-image-section {
  margin-top: 20px;
}

.inkox-support-image {
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}

.inkox-links-section {
  margin-top: 20px;
}

.inkox-links-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.inkox-links-section li {
  margin-bottom: 10px;
}

.inkox-links-section a {
  color: #0056b3;
  text-decoration: none;
}

.inkox-links-section a:hover {
  text-decoration: underline;
}

/* Menu items in sidebar */


.inkox-plus-sign {
  font-size: 18px;
  color: #999;
  padding-right: 10px;
}

@media (max-width: 768px) {
  
  .inkox-main-content {
    padding: 5px !important;
    box-sizing: border-box;
  }
}
