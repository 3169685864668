.testimonials {
  padding: 5rem 1rem;
      background: #f5f5f5;
  text-align: center;
  .container {
    max-width: 85%;
    margin: auto;
    height: 100%;
  }
  .slider-container {
    padding: 1rem 1.5rem;
    margin-top: 4rem;
  }
  .slick-initialized .slick-slide > div {

    .testimonialCard {
      margin: 1rem;
      text-align: left;
    }
  }
  .sub-title {
    margin-top: 0.5rem;
  }

  .slick-prev,
  .slick-next {
    height: 1.5rem;
    width: 1.5rem;
  }

  .slick-prev {
    left: -4rem;
  }
  .slick-next {
    right: -3.5rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: transparent;
    content: "";
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }

  .slick-prev:before {
    border-top: 5px solid $gray-clr2;
    border-left: 5px solid $gray-clr2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
  .slick-next:before {
    border-top: 5px solid $gray-clr2;
    border-right: 5px solid $gray-clr2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
}
