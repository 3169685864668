.banner {
  height: auto;
  gap: 2rem;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  text-align: center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.slick-dots {
    bottom: 16px;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    text-align: center;
    opacity: 1;
    color: transparent;
    background: #cccccc;
    border-radius: 30px;
    border: 1px solid;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1cc0d9;
        font-size: 23px;
            background: #1cc0d9;

}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 12px;
    padding: 0;
    cursor: pointer;
}

.bannerinner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.col-md-inner-one button {
    background: #f5f5f5;
    border: 0;
    letter-spacing: 3px;
    color: #1cbdd6;
    padding: 13px 28px;
    border-radius: 4px;
    font-size: 22px;
    margin-top: 23px;
    font-family: "Barlow Condensed", sans-serif !important;
    font-weight: bold;
    cursor: pointer;
}
.col-md-inner-two img {
    width: 85%;
}
.col-md-inner-one{
width:40%;
color: white;
}
.col-md-inner-two {
    width: 60%;
    text-align: center;
}
.bannermain-div {

    background-image: url(../../assets/images/9.webp);
    background-size:cover ;
}
.col-md-inner-one h1 {
    font-size: 48px;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif !important;
    font-weight: 100;
}
.col-md-inner-one p {
    font-size: 24px;
    font-family: "Barlow Condensed", sans-serif !important;
    font-weight: 100;
}
.line-banner {
  margin-top: 15px !important;
  margin-bottom: 15px !important;

    width: 100%;

    height: 1px;
    background: #f5f5f5;
    border: 0;
    border-radius: 18px;
}
// .side-sectoion-banner {
//     text-align: center;
// }

@media only screen and (max-width: 900px) {
    .bannerinner-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .col-md-inner-two img {
        width: 100%;
    }
    .col-md-inner-one {
        width: 100%;
        color: black;
        background: #f5f5f5;
        border-radius: 4px;
        margin: 0 auto;
        padding: 10px 12px;
        margin-bottom: 16px;
        box-sizing: border-box;
    }
    .side-sectoion-banner {
        text-align: center;
    color: #505057;

    }    
    .col-md-inner-one button {
        background: #1cbdd6;
        border: 0;
        letter-spacing: 3px;
        color: #f5f5f5;
        padding: 13px 28px;
        border-radius: 4px;
        font-size: 20px;
        margin-top: 23px;
        font-family: "Barlow Condensed", sans-serif !important;
        font-weight: bold;
        cursor: pointer;
    }
    .col-md-inner-one p {
        font-size: 18px;
        font-family: "Barlow Condensed", sans-serif !important;
        font-weight: 100;
    }
    hr.line-banner {
        background: gray;
    }
    .col-md-inner-two {
        width: 100%;
        text-align: center;
    }
    .col-md-inner-one h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-family: "Barlow Condensed", sans-serif !important;
        font-weight: 100;
    }
  }