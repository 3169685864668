.app-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  /* Container for form fields to appear side by side */
.form-row {
    display: flex;
    justify-content: space-between;
    gap: 40px; /* Adds space between the input fields */
  }
  
  /* Styling for each form group */
  .form-group {
    position: relative;
    flex: 1; /* Ensures both input fields have equal width */
  }
  

  /* Styling for valid input fields */
  .valid-input {
    border-color: #1cc0d9 !important; /* Green border when valid */
  }
  
  /* Checkmark styling */
  .success-check {
    position: absolute;
    right: 9px;
    top: 68%;
    transform: translateY(-50%);
    color: #0a6d12; /* Green color for the checkmark */
    font-size: 18px;
  }
  
  /* Label styling */

  
  /* Add styling for proper spacing */
  .form-group {
    margin-bottom: 20px;
  }
  



  
  .form-section {
    width: 90%;
  }
  
  .order-summary {
    width: 100%;
    border-radius: 8px;
  }
  .form-section-previews h3 {
    border-bottom: 1px solid #c5c5c7;
    padding-bottom: 15px;
    border-top: 1px solid #c5c5c7;
    padding-top: 15px;
}
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-group input {
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkbox-group label {
    margin-left: 10px;
  }
  
  .submit-btn {
    padding: 15px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .order-item {
    display: flex;
    margin-bottom: 20px;
  }
  
  .order-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .order-details {
    display: flex;
    flex-direction: column;
  }
  
  .total-price {
    margin-top: 20px;
  }
  
  .total-price h4 {
    font-size: 24px;
  }
  /* Add styles as necessary to match the original UI */

.app-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .form-section,
  .delivery-form,
  .payment-form {
    margin-bottom: 30px;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  

  
  .submit-btn {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }

  .app-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .main-content {
    width: 70%;
    margin-right: 20px;
  }
  
  .sidebarcheckout {
    width: 30%;
    display: flex;
    flex-direction: column;

    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #c5c5c7;
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .live-chat {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .live-chat img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .total-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .total-amount {
    font-size: 24px;
    font-weight: bold;
  }
  
  .pay-now-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-group input,
  .form-group select {
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    padding: 15px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .app-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .main-content {
    width: 70%;
    margin-right: 20px;
  }
  
  .sidebarcheckout {
    width: 30%;
    padding: 20px;
    border-radius: 8px;
  }
  
  .order-summary {
    display: flex;
    flex-direction: column;
  }
  
  .order-summary h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .order-summary .edit-link {
    float: right;
    text-decoration: none;
  }
  
  .order-total {
    margin-bottom: 10px;
  }
  
  .product-summary {
    display: flex;
    margin-top: 20px;
  }
  
  .product-imagecheckout {
    width: auto;
    height: 50px;
    margin-right: 0px;
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-group input,
  .form-group select {
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .submit-btn {
    padding: 15px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .app-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 93%;
    margin: 0 auto;
}
  
  .main-content {
    width: 60%;
    margin-right: 20px;
  }
  
  .sidebarcheckout {
    width: 30%;
    padding: 20px;
    border-radius: 8px;
  }
  
  .form-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  
  .success-check {
    color: green;
    font-size: 18px;
    margin-left: 10px;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .order-summary h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .order-summary .edit-link {
    float: right;
    text-decoration: none;
  }
  
  .order-total {
    margin-bottom: 10px;
  }
  
  .product-summary {
    display: flex;
    margin-top: 20px;
  }
  
  .product-image {
    width: auto;
    height: 50px;
    margin-right: 10px;
  }
  .shirt-checkout {
    display: grid;
    grid-template-columns: auto auto;
}

  .checkout h2 {
    margin: 0;
    font-size: 34px;
}
.checkout {
    text-align: center;
    margin-bottom: 18px;
}
  
.checkout p {
    color: #c5c5c7;
}
  .product-info {
    display: flex;
    flex-direction: column;
  }
  

  button.submit-btn img {
    vertical-align: middle;
    padding-left: 11px;
    width: 16px;
}
.preview-section {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c5c5c7;
    border-bottom: 1px solid #c5c5c7;
    padding: 12px 0px;
}
.editbtn-preview button.edit-btn {
    background: transparent;
    color: #1cc0d9;
    border: 0;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}
.inner-preview-section h2 {
    margin-top: 0;
    font-weight: 400 ;
}
.preview-details strong {
    font-weight: 100;
    font-size: 14px;
}
.preview-details p {
    font-size: 12px;
}

@media screen and (max-width: 900px) {
    .content-wrapper {
        display: block !important;
    }
    .main-content {
        width: 100%;
    }
    .sidebarcheckout {
        width: 100%;

    }
  }

  .payment-form {
    padding: 1em;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .payment-options {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .payment-options label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .payment-options input[type="radio"] {
    margin-right: 10px;
  }
  