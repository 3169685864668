/* App Layout */
.inkox-content-h2{
  font-size: 20px !important;

}
.inkox-content-para{
  font-size: 14px !important;

}





.passion-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
}

/* .passion-sidebar h2 {
  margin-bottom: 15px;
}

.passion-sidebar ul {
  list-style: none;
  padding: 0;
}

.passion-sidebar li {
  margin-bottom: 10px;
}

.passion-sidebar a {
  text-decoration: none;
  color: #333;
} */

.passion-main2 {
  width: 80%;
  display: flex;
  margin: 0 auto;
}

.passion-undermain {
  width: 25%;
}

.passion-browsebut {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.passion-primary-button:hover {
  background-color: #29b8e3;
}

.passion-divider {
  width: 80%;
  margin: 0 auto;
}

/* .passion-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f1f1f1;
}

.passion-sidebar h3, .passion-sidebar h4 {
  margin-top: 0;
}

.passion-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.passion-sidebar ul li {
  margin: 10px 0;
} */

.passion-main-content {
  width: 100% !important;
}

.passion-main-content h1 {
  font-size: 24px;
  margin-top: 0;
}

.passion-main-content p {
  font-size: 16px;
  line-height: 1.6;
}

.passion-image-section {
  margin-top: 20px;
}

.passion-image-section img {
  max-width: 100%;
  height: auto;
}

.passion-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  width: 96%;
  margin: 0 auto;
}

/* .passion-sidebar h3, .passion-sidebar h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.passion-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.passion-sidebar ul li {
  margin-bottom: 10px;
  font-size: 15px;
  color: #0073e6;
  cursor: pointer;
}

.passion-sidebar ul li:hover {
  text-decoration: underline;
} */

.passion-main-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.passion-main-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.passion-support-image {
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  
  .inkox-main-content {
    padding: 5px !important;
    box-sizing: border-box;
  }
}
