.accordion {
  .ant-collapse {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-color: transparent;
    .ant-collapse-item {
      border: none;
      margin-bottom: 1.5rem;
      .ant-collapse-header {
        display: flex;
        padding: 1rem 1.5rem;
        align-items: center;
        border: 1px solid $border-clr;
        background-color: $gray-clr6;
      }
      .ant-collapse-content-box {
        padding: 1rem 1.5rem;
      }
    }
  }

  &.small {
    .ant-collapse {
      .ant-collapse-item {
        margin-bottom: 1rem;
        .ant-collapse-content-box {
          padding: 0.5rem 1rem;
          .description {
            line-height: 1.375rem;
          }
        }
      }
    }
  }
}
.iconplus {
  width: 10px;
}