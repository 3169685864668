@font-face {
    font-family: 'MyCustomFont';
    src: url('../fonts/Fontspring-DEMO-zuumerough-italic.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

  body {
    font-family: 'MyCustomFont', sans-serif;
  }
  