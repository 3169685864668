footer {
  padding: 5rem 0 1.5rem 0;
  border-top: 20px solid #1cc0d9;
  margin-top: 0;
  background: #f5f5f5;

  .socialIcons {
    margin-top: 3rem;
  }
  .footer-logo {
    display: block;
    max-width: 11.25rem;
    margin-bottom: 1rem;
    img {
      width: 100%;
    }
  }
  .contact-title {
    margin-bottom: 2rem;
  }

  ul.footer-links {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 3px solid $gray-clr3;
    li {
      margin-bottom: 0.8rem;
      a {
        font-size: 1.063rem;
      }
    }
  }


  p.description {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 3px solid $gray-clr3;
    font-size: 1.063rem;
    font-weight: unset;
  }
  footer.footer-main {
    border-top: 20px solid #1cc0d9;
    margin-top: 0;
    background: #f5f5f5;
}
  .copyright {
    margin-top: 5rem;
    padding: 2rem 0;
    background-color: #ecf0f1;
  }
}
