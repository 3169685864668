.productSlider {
  position: relative;
  width: 100%;
  height: auto;

  .slider-container {
    display: flex;
    gap: 1rem;
    margin: 0.1rem;
    flex-direction: row; /* Set to row for horizontal scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Enable horizontal snapping */
    scroll-behavior: smooth; /* Smooth scrolling */
    padding: 0.5rem 0; /* Add some padding if needed */

    .slider-img {
      cursor: pointer;
      height: auto;
      width: 4.188rem;
      object-fit: cover;
      background-color: #fff;
      flex-shrink: 0; /* Prevents shrinking for better scroll behavior */
      scroll-snap-align: start; /* Each image will snap into place */
    }

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for WebKit browsers */
    }

    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;     /* Hide scrollbar for Firefox */
  }

  .main-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.icon-product-image {
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 9999999999999;

  align-items: center;
}
svg.slider-icon.left:hover {
  fill: black !important;
  background: #f5f5f5d6 !important;
}
svg.slider-icon.right:hover {
  fill: black !important;
  background: #f5f5f5d6 !important;
}

button.slider-button {
  background: transparent;
  border: 1px solid #f0f2f5;
}
button.slider-button.active {
  border: 2px solid #1cc0d9;
}