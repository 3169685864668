.main-color-image {

    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}
tr.main-color-image td {
    border: 1px solid #cccccc;
    padding: 30px 0px;
}
/* .colorbox-workorder{
    border: 1px solid gray;

} */
.product-name-work{
    text-align: center;
    margin-bottom: 30px  !important;
}
div#work-orders-container {

    padding: 20px;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
}
td.hover-colorss {
    border: 0 !important;
}
.image-workorder{
    padding: 0 !important;
}