.breadcrumb {
  background-color: $offwhite-clr2;
  display:none;
  padding: 1.5rem 0;
  .custom-search {
    background-color: $white-clr;
  }
  a,
  span {
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: capitalize;

    &.active {
      color: $skyblue-clr1;
    }
    &.disabled {
      text-decoration: none;
      pointer-events: none;
      color: $black-clr1;
    }
  }
}
