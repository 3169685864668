
.trendingProducts .sub-title {

  text-align: center;
  margin-top: .5rem;
}



html {
  font-size: 16px;
    overflow-x:hidden !important;

}
button.slider-button.right{
  display: none;
}
button.slider-button.left{
  display: none;
}
a {
  color: $black-clr1;
}
a:hover {
  cursor: pointer !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
input,
p,
label,
span,
div,
body {
   font-family: "Montserrat", serif;
}

body {
  position: relative;
 
}

li {
  list-style-type: none;
}

.text-black {
  color: $black-clr1;
}

.text-white {
  color: $white-clr;
}

.text-red {
  color: $red-clr1;
}

.text-skyblue1 {
  color: $skyblue-clr1;
}

.text-skyblue {
  color: $skyblue-clr5;
}
.text-skyblue4 {
  color: $skyblue-clr4;
}

.text-skyblue6 {
  color: $skyblue-clr6;
}

.bg-yellow {
  background-color: $yellow-clr1;
}
.bg-black {
  background-color: $black-clr1;
}
.bg-white {
  background-color: $white-clr;
}

.fs-117 {
  font-size: 7.313rem;
}
.fs-65 {
  font-size: 4.063rem;
}
.fs-46 {
  font-size: 2.875rem;
}
.fs-43 {
  font-size: 2.688rem;
}
.fs-42 {
  font-size: 2.625rem;
}
.fs-41 {
  font-size: 2.563rem;
}
.fs-40 {
  font-size: 2.5rem;
}
.fs-38 {
  font-size: 2.375rem;
}
.fs-33 {
  font-size: 2.063rem;
}
.fs-31 {
  font-size: 1.938rem;
}
.fs-30 {
  font-size: 1.875rem;
}
.fs-27 {
  font-size: 1.688rem;
}
.fs-26 {
  font-size: 1.625rem;
}
.fs-25 {
  font-size: 1.563rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-21 {
  font-size: 1.313rem;
}
.fs-20 {
  font-size: 1.25rem;
}

.fs-19 {
  font-size: 1.188rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-17 {
  font-size: 1.063rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-14 {
  font-size: 0.875rem;
}
.fs-13 {
  font-size: 0.813rem;
}
.fs-12 {
  font-size: 0.75rem;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.text-center {
  text-align: center;
}

// INPUT CSS
.ant-form-item {
  margin-bottom: 2rem;
  .phoneInput {
    height: 3.25rem;
    border: 1px solid $border-clr;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-size: 1rem;
    background-color: $white-clr;
    * {
      border: none !important;
    }
    .flag-dropdown {
      background-color: transparent;
    }
    .form-control {
      height: 100%;
      padding-left: 3rem;
    }
  }
  .ant-input {
    height: 3.25rem;
    border: 1px solid $border-clr;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-size: 1rem;
  }
  .ant-input-affix-wrapper {
    height: 3.25rem;
    border: 1px solid $border-clr;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    padding-block: 0 !important;
    overflow: hidden;
    .ant-input {
      font-size: 1rem;
    }
  }
}

// SUB MENUES
.ant-menu-submenu-popup {
  padding-top: 2rem;
  .ant-menu {
    min-width: 20rem !important;
    background-color: #ffff;
    padding: 0.3rem !important;

    border-radius:8px !important;
    box-shadow: 0px 20px 10px $shadow-clr;


    .ant-menu-item {
      display: flex;
      align-items: center;
      height: 3.438rem !important;
      margin-bottom: 0.5rem !important;
      background-color: $white-clr;
      border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      -ms-border-radius: 0 !important;
      -o-border-radius: 0 !important;
      font-size: 1.125rem;
      font-weight: 500;
      &.ant-menu-item-selected,
      &:hover {
        color: #1cc0d9 !important;
        background-color: #f5f5f5 !important;
       border-radius:8px !important;
      }
    }
  }
}

// SEARCH CSS

.custom-search {
  height: 3rem;
  width: 100%;
  border: 1px solid $border-clr;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  display: flex;
  align-items: center;
  padding: 0 1rem !important;

  * {
    font-size: 1.3rem;
    box-shadow: none !important;
    border: none;
    background-color: transparent !important;
    background: transparent;
  }
}

.custom-select {
  height: 3rem;
  width: 100%;
  border: 1px solid $border-clr;
  border-radius: 0.313rem;
  -webkit-border-radius: 0.313rem;
  -moz-border-radius: 0.313rem;
  -ms-border-radius: 0.313rem;
  -o-border-radius: 0.313rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  .ant-select-selector,
  .ant-select-selection-search,
  input {
    // height: 100% !important;
  }
  * {
    font-size: 1.3rem;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
    background: transparent;
  }
}

.filters {
  border: none;
  background-color: $white-clr;
  box-shadow: 0 0 0 0 !important;
  border: none;
  .ant-collapse-item {
    background-color: $white-clr;
    box-shadow: 0 0 0 0 !important;
    border: none;
    &::after {
      content: "";
      display: block;
      border-bottom: 1px solid $border-clr2;
      width: 92%;
      margin-left: 0.3rem;
      // margin: auto;
    }

    .ant-collapse-header {
      padding-left: 0;
      * {
        background-color: $white-clr;
        box-shadow: 0 0 0 0 !important;
        border: none;
      }
      .ant-collapse-arrow {
        svg {
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
        }
      }

      .ant-collapse-header-text {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-arrow {
          svg {
            transform: rotate(-90deg) !important;
            -webkit-transform: rotate(-90deg) !important;
            -moz-transform: rotate(-90deg) !important;
            -ms-transform: rotate(-90deg) !important;
            -o-transform: rotate(-90deg) !important;
          }
        }
      }
    }
    .ant-collapse-content {
      border: none;
    }
  }
}

.container, .shipping, .newArrival.newarrivalorignal,.BrandGrid,.productsWithCategories,.faqs,.productsWithFilters,.reviews {
  max-width: 1200px !important;
  width: 100% !important;
  margin: 0 auto;
  box-sizing: border-box;
}

.colors-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.color-box {
  height: 25px !important;
  width: 25px !important;

  margin-bottom: 1rem;
  border: 1px solid $border-clr;
  border-radius: 4px !important;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.underline {
  text-decoration: underline;
}

.btns-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.openQouteCalc {
  padding: 0.05rem 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  background-color: $skyblue-clr1;
  border-radius: 0.563rem;
  -webkit-border-radius: 0.563rem;
  -moz-border-radius: 0.563rem;
  -ms-border-radius: 0.563rem;
  -o-border-radius: 0.563rem;
}

.priceSlider {
  .ant-slider-track {
    height: 0.313rem;
    background-color: $skyblue-clr1 !important;
  }
  .ant-slider-dot {
    height: 1.063rem;
    width: 1.063rem;
    inset-block-start: -0.375rem;
    background-color: $skyblue-clr1 !important;
    border: none;
  }
  .ant-slider-handle {
    height: 1.063rem;
    width: 1.063rem;
    &::after {
      inset-inline-start: -1px;
      box-shadow: 0 0 0 0 !important;
      height: 1.063rem !important;
      width: 1.063rem !important;
      inset-block-start: -0.175rem !important;
      background-color: $skyblue-clr1 !important;
    }
  }
  .ant-slider-mark {
    top: 1.25rem;
    .ant-slider-mark-text {
      font-size: 1rem;
      font-weight: 600;
      min-width: 3.125rem;
    }
  }
}

.main-spinner {

  height: 100vh;
  align-content: center;
}
h2.inkoxloading {
  text-align: center;
  font-family: Barlow Condensed, sans-serif !important;
  font-size: 33px;
  color: #f5f5f5;
}
.materialsFilter {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  .ant-radio-wrapper {
    font-size: 1rem;
    font-weight: 600;
  }
}

.customTabs {
  margin: 2rem 0;
  justify-content: center;
  .ant-tabs-nav-list {
    justify-content: center;
    width: 100%;
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      font-size: 1rem;
      font-weight: 700;
      min-height: 2.063rem;
      background-color: $white-clr;
      padding: 0 3rem;
      border: none;
      &.ant-tabs-tab-active {
        background-color: $offwhite-clr4;
        * {
          border: none;
          color: $red-clr1;
        }
      }
    }
  }
  .ant-tabs-tabpane {
    padding: 2rem;
  }
}

.d-desktop {
  display: block;
}

.d-mobile {
  display: none;
}

@media (min-width: 1900px) {
  .container, .shipping, .newArrival.newarrivalorignal,.BrandGrid,.productsWithCategories,.faqs,.productsWithFilters,.reviews {
    max-width: 1500px !important;
    width: 100% !important;
    margin: 0 auto;
    box-sizing: border-box;

  }
}
@media (min-width: 1600px) {
  .container, .shipping, .newArrival.newarrivalorignal,.BrandGrid,.productsWithCategories,.faqs,.productsWithFilters,.reviews {
    max-width: 1400px !important;
    width: 100% !important;
    margin: 0 auto;
    box-sizing: border-box;

  }
}
@media (max-width: 1200px) {
  .container, .shipping, .newArrival.newarrivalorignal,.BrandGrid,.productsWithCategories,.faqs,.productsWithFilters,.reviews {
    max-width: 1200px !important;
    width: 100% !important;
    margin: 0 auto;
    box-sizing: border-box;
    padding:0px 16px;

  }
}

@media (max-width:1024px){
  // .container, .shipping, .newArrival.newarrivalorignal,.BrandGrid,.productsWithCategories,.faqs,.productsWithFilters,.reviews {
 
  //   box-sizing: border-box;
    
  //     }
}
@media (max-width: 900px) {


  .d-desktop {
    display: none;
  }
  .d-mobile {
    display: block;
  }
  .fs-117 {
    font-size: 6.313rem;
  }
  .fs-65 {
    font-size: 3.063rem;
  }
  .fs-40 {
    font-size: 2rem;
  }
  html {
    font-size: 12px;
  }

  header {
    .brand-logo {
      padding-bottom: 0;
    }
  }



  .newArrival {
    .box {
      height: 25rem;
      img {
        object-fit: cover;
      }
    }
  }

  .customTabs {
    .ant-tabs-nav-list {
      flex-wrap: wrap;
      gap: 1rem;
      .ant-tabs-tab {
        width: 45%;
        margin: 0;
        justify-content: center;
      }
    }
  }

  .offerBanner {
    background-position: right;
  }

  .brandDeserves {
    .container {
      max-width: 90%;
      .brands-container {
        border-bottom: none;

        .brandCard {
          box-shadow: none;
          border-bottom: 1px solid #707070;
        }
      }
    }
  }
  .tipOfTheDay {
    text-align: center;
    .content-row {
      > div:first-child {
        padding-bottom: 0 !important;
      }
    }
  }

  footer {
    .contact-no {
      margin: 2rem 0;
    }

    .socialIcons {
      margin: 2rem 0;
    }
    .copyright {
      margin-top: 2rem;
    }
  }

  .productsWithFilters {
    padding-inline: 1rem;
    .page-header {
      max-width: 80%;
      margin-inline: auto;
      justify-content: center;
    }
    .search-container {
      flex-wrap: wrap;
      margin: 0 0 2rem 0;
      .title {
        width: 100%;
      }
    }
    .filter-content {
      padding: 0 0.5rem;
      .productListing {
        padding-left: 0;
        padding-bottom: 0;
        row-gap: 2rem !important;
      }
    }
    .filter-btn {
      justify-content: flex-end;
      display: flex;
      gap: 1rem;
      width: 100%;
      box-sizing: border-box;
      align-items: center;
    }
    .filter-sidebar {
      position: fixed;
      padding: 1rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
      z-index: 10;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      background-color: $white-clr;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      &.show {
        transform: translate(0);
        -webkit-transform: translate(0);
        -moz-transform: translate(0);
        -ms-transform: translate(0);
        -o-transform: translate(0);
      }
      .filters {
        border-right: none;
      }
    }
    .sidebar-close-btn {
      margin: 1rem 1rem;
      text-align: right;
      svg {
        font-size: 2rem;
      }
    }
  }

  .productsWithCategories {
    padding-inline: 1rem;
    .page-header {
      max-width: 80%;
      margin-inline: auto;
      justify-content: center;
    }
    .search-container {
      flex-wrap: wrap;
      margin: 0 0 2rem 0;
      .title {
        width: 100%;
      }
    }
    .filter-content {
      padding: 0 0.5rem;
      .productListing {
        padding-left: 0;
        padding-bottom: 0;
        row-gap: 2rem !important;
      }
    }
    .filter-btn {
      justify-content: flex-end;
      display: flex;
      gap: 1rem;
      width: 100%;
    }
    .filter-sidebar {
      position: fixed;
      padding: 1rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
      z-index: 10;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      background-color: $white-clr;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      &.show {
        transform: translate(0);
        -webkit-transform: translate(0);
        -moz-transform: translate(0);
        -ms-transform: translate(0);
        -o-transform: translate(0);
      }
      .filters {
        border-right: none;
      }
    }
    .sidebar-close-btn {
      margin: 1rem 1rem;
      text-align: right;
      svg {
        font-size: 2rem;
      }
    }
  }

  .faqs {
    padding: 2rem 1.5rem;
    .fs-30 {
      font-size: 1.2rem;
    }
    .ant-collapse-arrow {
      width: 1.2rem;
    }
  }

  .productListing {
    .ant-row {
      row-gap: 2rem !important;
      justify-content: center;
    }
  }


  .productSlider {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .main-img {
      height: auto;
    }
    .slider-container {
      margin: 0;
      overflow: hidden;
      overflow-x: auto;
      position: relative;
      flex-direction: row;
    }
  }

  .productDetail {
    .product-overview {
      .item-detail {
        .colors {
          .colors-container {
            justify-content: flex-start;
          }
        }

        > .ant-row {
          gap: 0 !important;
        }
      }
    }
    .additional-information-container {
      padding: 3rem 0;

      .qoute-calc {
        margin-bottom: 3rem;
      }
    }
  }
  .reviews {
    padding: 3rem 1.5rem;

    .border-container {
      padding: 2rem 1rem;
      .reviews-container {
        padding-right: 0;
        .review-box {
          margin-bottom: 1rem;
          .star-svg {
            height: 2rem !important;
            width: 2rem !important;
          }
          .ant-row {
            > div {
              text-align: left !important;
              padding-inline: 0;
              border: none !important;
            }
            > div:last-child {
              border: none;
              padding: 1rem 0;
            }

            .flex-container {
              margin-left: 0;
              width: 100%;
            }
          }
        }

        .reply-box {
          max-width: 100%;
          margin-bottom: 1rem;
          .ant-row {
            > div {
              border: 0 0 0.313rem 0 solid $white-clr !important;
              border-right: none !important;
            }
            .flex-container {
              margin-left: 0;
            }
            > div:last-child {
              padding: 1rem;
            }
          }
        }
      }
    }
  }
  .desktop-menu {
    display: none !important;
  }

  .cart {
    .main-title {
      margin-bottom: 0;
    }
    .cart-item {
      .cart-item-header {
        padding-inline: 0;
        padding-bottom: 0.5rem;
      }
      .cart-info {
        padding-inline: 0;
        padding-bottom: 2rem;
        .inline-row {
          flex-direction: column;
        }
        .item-feature {
          align-items: flex-start;
        }
        .price-cotainer {
          margin-top: 1rem;
          gap: 1rem;
          .items-total-price .description {
            font-size: 1.3rem;
            font-weight: 400;
            text-align: right;
          }
        }
      }
    }
    .deliveryOptions {
      .ant-radio-group {
        .ant-radio-wrapper {
          gap: 0;
          width: 100%;
          margin: 0;
          label {
            text-indent: 0;
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  .productDeliveryProcess {
    .customStepsForForm {
      // flex-direction: column;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow: hidden;
      .ant-steps-item {
        min-height: 55px;
        .ant-steps-item-content {
          min-height: 55px;
          .title {
            padding-left: 1rem;
            font-size: 1.5rem !important;
            position: relative;
            z-index: 1;
          }
        }
        &::before,
        &::after {
          border-width: 35px;
        }
      }
    }
    .left-content {
      .blueBtn {
        margin-inline: auto;
      }
      margin-bottom: 5rem;
    }
    .product-info-box {
      margin-bottom: 5rem;
    }
    .para-three {
      font-size: 1.2rem;
    }
    .timings {
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 3rem;
      .description {
        font-size: 1.2rem;
        border: none;
        padding-left: 0;
      }
    }

    .chatTalk {
      &.large {
        .title.fs-12 {
          font-size: 0.8rem !important;
        }
        .title.fs-16 {
          font-size: 1.2rem !important;
        }
      }
    }
  }
}
// header.header .ant-row.ant-row-space-between.ant-row-middle.row-wesite-header.css-nllxry {
//   padding-top: 0px !important;
//   padding-bottom: 0px !important;
// }
@media (min-width: 300px) {
  .desktop-menu {
    display: block !important;
  }

  .mobile-menu {
    display: none !important;
  }
}



@media screen and (max-width: 900px) {
body{
  overflow-x: hidden;
}
html {

  -webkit-tap-highlight-color: rgb(255, 255, 255);
}
}
@media (max-width: 1200px) {
  // html {
  //   font-size: 10px;
  // }
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: block !important;
    padding: 0 !important;
  }
}

@media (min-width: 1100px) {
  html {
    font-size: 10px;
  }
  .column-homepage-product {
    flex: 0 0 20% ;
  }
}

@media (min-width: 1300px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1500px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  header .ant-menu {
    gap: 0rem;
  }
  .container {
    max-width: 95%;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 90%;
  }
  html {
    font-size: 15px;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 85%;
  }
  html {
    font-size: 16px;
  }
}
