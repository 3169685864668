.tipOfTheDay {
  height: 30.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .content-row {
    margin: auto;
    > div {
      background-color: #ffffff40;
      padding: 1.5rem;
      .description {
        text-align: center;
      }
    }
  }
}
