/* Basic Layout */
.cart-page-container {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}
.cart-page-main{

    padding: 20px 100px;
}
.catimage {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 12px;
}
.bag-text {
    margin-bottom: 30px;
}
  .cart-section {
    flex-basis: 60%;
    min-width: 300px;
  }
  
  .order-summary-section {
    flex-basis: 35%;
    min-width: 300px;
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    height: 260px;
  }
  
  /* Cart Item */
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    align-items: start;
}
.cart-item-name{
    border: 1px solid #ddd;
    margin-bottom: 35px;

}
.Design-name {
    background: #f5f5f5;
    margin: 0;
    display: flex;
    padding: 9px 16px;
    justify-content: space-between;
    align-items: center;
}
  .product-image {
    width: 100px;
  }
  h4.text-design-name {
    margin: 0;
}
h4.text-design-name span a, p.edit-size-design a {
   color: #1cc0d9;
   text-decoration: none;
   font-size: 12px;
   font-weight: 100;
   padding-left: 12px;
}


  
  .cart-item-details {
    flex-grow: 1;
    padding: 0 20px;
  }
  
  .cart-item-price {
    font-size: 1rem;
    font-weight: bold;
  }
  .cart-item-details h3 {
    margin: 0;
    font-size: 1rem;
}
  

label.delivery-option {
  display: flex;
  margin: 11px 0px;
}
input.delivery-radio-input {
  margin-right: 13px;
  width: 3%;
  outline: none;
}
.delivery-option-content {
  font-size: 12px;
  box-sizing: border-box;
}
  .remove-item {
    background: none;
    border: none;
    color: red;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .quantity-selector select {
    padding: 5px;
    margin-top: 10px;
  }
  
  /* Order Summary */
  .order-summary h3 {
    margin-bottom: 10px;
    font-size: 1rem;
    text-transform: capitalize;
  }
  
  
  .order-summary p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
  
  .price {
    font-weight: bold;
  }
  
  .checkout-button {
    width: 100%;
    padding: 10px;
    background-color:#202B40;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .Design-name div img {
    width: 20px;
}
.delivery-options-container {
  padding: 12px 20px;
  border: 0px solid #1cc0d9;
  background: #f5f5f5;
}
.google-pay-button {
    width: 100%;
    padding: 10px;
    border: 1px solid #202b40 ;
    background-color: #ffffff;
    color: #202b40;
    box-sizing: border-box;
  
    cursor: pointer;
    font-size: 1rem;
}span.size-preview {
    background: #f5f5f5;
    margin: 2px;
    padding: 2px;
    box-sizing: border-box;
}
.cart-item-details p {
    font-size: 12px;
    padding: 5px 0px;
}
p.preview-size-main {
    margin-top: 3px;
    MARGIN-BOTTOM: 3px;
}
.sticky-checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ddd;
    padding: 15px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    box-sizing: border-box;
  }
  
  .sticky-checkout-button {
    padding: 10px 20px;
    background-color: #202B40;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  .check-stiky-inner {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 10px;
    
}
.sticky-checkout {
    display: none;
}
  
  /* Responsive Design */
  @media (max-width: 900px) {
    .cart-page-container {
      flex-direction: column;
      box-sizing: border-box;
    }
    .cart-page-main {
        padding: 20px 20px;
    }
  
    .cart-section,
    .order-summary-section {
      width: 100%;
      box-sizing: border-box;
    }
    .order-summary-section {
        margin-bottom: 100px !important;
    }
    .sticky-checkout {
        display: block;
    }
  }
  