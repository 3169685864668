.productsWithFilters {
  padding: 4rem 0px;
  .title-section {
    text-align: center;
    .sub-title {
      margin-top: 0.5rem;
    }
  }

  .page-header {
    margin-bottom: 3rem;
  }

  .header-row {
    padding: 1rem 0.5rem;
    margin: 1rem 0;
    border-top: 1px solid $border-clr2;
    border-bottom: 1px solid $border-clr2;
    > div {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    > div:first-child {
      border-right: 1px solid $border-clr2;
    }
  }
  .search-container {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    gap: 2rem;
    .title {
      width: 30%;
    }
  }
  .sorting-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    .image-container {
      display: flex;
      align-items: baseline;
      .sort-icon {
        height: 0.813rem;
        margin-right: 0.5rem;
      }
    }
  }

  .filter-content {
    padding: 0 0.5rem;
    .filters {
      border-right: 1px solid $border-clr2;
    }
    .productListing {
      padding: 4rem 0 4rem 1.5rem;
    }
  }
}
.colors-container.filter-color span.ant-checkbox {
  display: none;
}
