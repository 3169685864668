.paymentIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  padding: 0px;
  li {
    text-align: center;
    height: 2.5rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
