.testimonialCard {
  height: 19.188rem;
  background-color: #fff;
      border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > .description {
    line-height: 1.813rem;
    font-weight: 500;
    min-height: 7.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .card-image-row {
    border-radius: 100%;
    display: flex;
    justify-content: space-between;
    .line {
      content: "";
      display: block;
      height: 0.25rem;
      background-color: $gray-clr1;
      width: 80%;
      margin-top: 1rem;
    }
    .card-image {
      padding: 0.5rem;
      height: 4.25rem;
      width: 4.25rem;
      background-color: $white-clr;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .designation {
      margin-top: 0.2rem;
    }
  }
}
