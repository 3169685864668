.offerBanner {
  height: 52rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .offer-box {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    border-radius: 1.5rem;
    padding: 0 2rem;
    width: fit-content;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .countdownTimer {
    margin: 2rem 0;
  }
  .simpleButton {
    border: none;
  }
}


.how-to-design-section {
  text-align: center;
  background: #f5f9ff;
  padding: 40px 20px;
  border-radius: 10px;
}

.step-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.step-number {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 10px;
}
.inner-offerbanner .sub-title {
  text-align: center;
  margin-top: 0.5rem;
}
.inner-offerbanner {
  padding-bottom: .5rem;
}
.inner-offerbanner {
  padding-bottom: 5rem;
}
.innerheading-offerbanner{
  border-bottom: 1px solid;
}
.innerheading-offerbanner p {
  padding-top: 9px;
}
.main-orrderbanner {
  /* background: #e50c0c; */
  margin-bottom: 1rem;
  padding: 0px 100px;
  border-top: 0.813rem solid #4ea4c9;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1024px){

  .main-orrderbanner {

    padding: 5rem 1rem;
  
}
  
}