.reviews {
  padding: 5rem 4rem;
  .heading-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin-bottom: 3rem;
    .review-row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
  .border-container {
    border: 1px solid $border-clr;
    padding: 2rem 1.5rem;
    border-radius: 1.375rem;
    -webkit-border-radius: 1.375rem;
    -moz-border-radius: 1.375rem;
    -ms-border-radius: 1.375rem;
    -o-border-radius: 1.375rem;
    .reviews-container {
      overflow: hidden;
      padding-right: 2rem;
      overflow-y: auto;
      max-height: 66rem;

      .review-box {
        margin-bottom: 2rem;
        background-color: $gray-clr7;
        .ant-row {
          padding: 1rem;
          > div {
            padding: 0.5rem 1rem;
            border-bottom: 0.313rem solid $white-clr;
          }
          > div:nth-child(2) {
            text-align: center;
            border-left: 0.313rem solid $white-clr;
            border-right: 0.313rem solid $white-clr;
          }
          > div:last-child {
            border: none;
            padding: 2rem 1rem;
            .title {
              line-height: 2.5rem;
            }
          }

          .flex-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 2rem;

            .verified-box {
              position: relative;
              .unverified-title {
                color: $gray-clr3;
              }

              .checkSign {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
              }
            }
            .time {
              color: $gray-clr8;
            }
          }

          .review {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .reply-box {
        margin-bottom: 2rem;
        max-width: 80%;
        margin-left: auto;
        background-color: $gray-clr9;
        .ant-row {
          padding: 1rem;
          > div {
            padding: 0.5rem 1rem;
            border-bottom: 0.313rem solid $white-clr;
          }
          > div:nth-child(1) {
            text-align: center;
            border-right: 0.313rem solid $white-clr;
          }
          > div:last-child {
            border: none;
            padding: 2rem 1rem;
            .title {
              line-height: 2.5rem;
            }
          }

          .flex-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 2rem;

            .verified-box {
              position: relative;
              .checkSign {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
              }
            }
            .time {
              color: $white-clr;
            }
          }

          .review {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
