/* App Layout */


.inkox-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
}


.inkox-content-h1{
  font-size: 24px;
}
.inkox-content-h2{
  font-size: 20px;
}
.inkox-content-h3{
  font-size: 18px;
}
.inkox-content-para{
  font-size: 14px;
}
.inkox-lab{
  font-size: 15px;
}
/* Sidebar Styling */
/* .inkox-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f1f1f1;
}

.inkox-sidebar h2 {
  margin-bottom: 15px;
}

.inkox-sidebar ul {
  list-style: none;
  padding: 0;
}

.inkox-sidebar li {
  margin-bottom: 10px;
}

.inkox-sidebar a {
  text-decoration: none;
  color: #333;
} */

/* Main Content Styling */
.inkox-main-content {
  padding: 20px;
  max-width: 100%;
}

.inkox-main-content h1 {
  font-size: 28px;
  margin-top: 0;
  color: #333;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* Image Section */
.inkox-image-section {
  margin-top: 20px;
}

.inkox-support-image {
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}

/* Divider */
.inkox-divider {
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid #aaa;
}

/* Buttons */
.inkox-browsebut {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.inkox-primary-button:hover {
  background-color: #29b8e3;
}
@media (max-width: 768px) {
  
  .inkox-main-content {
    padding: 5px !important;
    box-sizing: border-box;
  }

  .inkox-browsebut {
    padding: 4px 6px;
}



}
