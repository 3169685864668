.cart {
  padding: 5rem 0;
  .main-title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .review-box {
    margin-bottom: 1.5rem;
    padding: 1rem 2rem;
    background-color: $white-clr;
    box-shadow: 0 3px 6px #00000016;
    > .description {
      font-size: 1.188rem;
      font-weight: 600;
    }
    .inline-text {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
      .description {
        font-size: 1.063rem;
        font-weight: 400;
      }
      a {
        color: $skyblue-clr1;
        font-size: 1.063rem;
        font-weight: 700;
      }
    }
  }
  .cart-item {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #fbfbfb;
    box-shadow: 0 10px 6px #00000016;
    .cart-item-header {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-clr;
      padding-inline: 1rem;
      .description {
        color: $skyblue-clr1;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        a {
          color: $skyblue-clr1;
        }
      }
    }
    .cart-info {
      padding: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $border-clr;
      label {
        font-size: 1.313rem;
        font-weight: 600;
      }
      .inline-row {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        .images {
          display: flex;
          img {
            height: 7.5rem;
            width: 7.438;
            object-fit: contain;
          }
        }
        .color-size-box {
          > div {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
            .color-box {
              border-radius: 0.313rem;
              display: inline-block;
              height: 1.375rem;
              width: 1.375rem;
              border: 1px solid $border-clr;
              -webkit-border-radius: 0.313rem;
              -moz-border-radius: 0.313rem;
              -ms-border-radius: 0.313rem;
              -o-border-radius: 0.313rem;
              margin-bottom: 0;
              margin-right: 0.5rem;
            }
            .description {
              display: flex;
            }
          }
          .edit-size-btn {
            color: $skyblue-clr1;
            font-size: 1.313rem;
            font-weight: 500;
          }
        }
      }
      .item-feature {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .price-cotainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2rem;
        .price-row {
          display: flex;
          gap: 3rem;
          align-items: center;
          .discounted-price {
            color: $skyblue-clr1;
          }
          .actual-price {
            color: #9f9f9f;
            text-decoration: line-through;
          }
        }
        .items-total-price {
          .description {
            font-size: 1.188rem;
            font-weight: 400;
            text-align: right;
          }
        }
      }
    }
    .add-color-btn {
      padding-inline: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.313rem;
      font-weight: 600;
      margin-bottom: 2rem;
      img {
        height: 1.188rem;
        width: 1.188rem;
      }
    }
    .add-product-btn {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.313rem;
      font-weight: 600;
      img {
        height: 1.188rem;
        width: 1.188rem;
      }
    }
  }
  .deliveryOptions {
    padding: 3rem 1.5rem 2rem 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #fbfbfb;
    box-shadow: 0 10px 6px #00000016;
    .ant-radio-group {
      .ant-radio-wrapper {
        gap: 1rem;
        padding: 1rem 0;
        align-items: flex-start;
        border-bottom: 1px solid $border-clr;
        .ant-radio {
          align-self: flex-start;
          margin-top: 0.5rem;
        }
        &.ant-radio-wrapper-checked {
          .ant-radio {
            align-self: flex-start;
            margin-top: 0.5rem;
            .ant-radio-inner {
              border-color: $skyblue-clr1;
              background-color: $skyblue-clr1;
            }
          }
        }
        label {
          display: flex;
          flex-direction: column;
          font-size: 1.188rem;
          text-indent: 1rem;
        }
        .days {
          color: #bfbfbf;
          font-size: 1.188rem;
        }
      }
    }
    .show-more-deliver-options-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $skyblue-clr1;
      font-size: 1.188rem;
      margin-top: 2rem;
    }
  }

  .order-summary-container {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #fbfbfb;
    box-shadow: 0 10px 6px #00000016;
    .list {
      margin: 2rem 0;
      li {
        font-size: 1.188rem;
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child {
          margin-top: 3rem;
        }
      }
    }
    .view-promo-btn {
      color: #27a6ff;
      font-size: 1.063rem;
    }
  }
  .checkout-btns {
    margin-top: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .checkout-btn {
      color: $white-clr;
      font-size: 1.875rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #27a6ff;
      border: 2px solid #27a6ff;
      min-height: 4.438rem;
      border-radius: 2.25rem;
      -webkit-border-radius: 2.25rem;
      -moz-border-radius: 2.25rem;
      -ms-border-radius: 2.25rem;
      -o-border-radius: 2.25rem;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      &:hover {
        color: #27a6ff;
        background-color: $white-clr;
      }
    }

    .description {
      font-size: 1.188rem;
      font-weight: 400;
    }

    .paypal-btn {
      background-color: #0070ba;
      color: $white-clr;
      font-size: 1.375rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #0070ba;
      min-height: 3rem;
      border-radius: 2.25rem;
      -webkit-border-radius: 2.25rem;
      -moz-border-radius: 2.25rem;
      -ms-border-radius: 2.25rem;
      -o-border-radius: 2.25rem;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      &:hover {
        color: #0070ba;
        background-color: $white-clr;
      }
    }
  }
}
