

/* App Layout */
.inkox-menu-item {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-div-other-pages {
  background: #f5f5f5;
}

.mui-icon {
  width: 20px;
  height: 20px;
}

.inkox-submenu {
/*  padding-left: 20px;
  background-color: #e9e9e9;*/
}

.inkox-submenu-item {
  display: block;
  padding: 8px 0px;
  font-size: 14px;
  color: #555;
  text-decoration: none;
}

.inkox-submenu-item:hover {
  color: #000;
}


.inkox-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  column-gap: 20px;
}

/* Sidebar Styling */
.inkox-sidebar {
  width: 25% !important;

  background-color: #f5f5f5;
  box-sizing: border-box;
}

.inkox-sidebar h3,
.inkox-sidebar h4 {
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 10px; /* Adjusted for better spacing */
  color: #333;
}

.inkox-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.inkox-sidebar ul li {
  margin: 12px 0;
  font-size: 15px;
  color: #0073e6;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth hover effect */
}

.inkox-sidebar ul li:hover {
  text-decoration: underline;
  color: #005bb5; /* Slightly darker hover color */
}

/* Main Content Styling */
.inkox-image-section {
  margin-top: 20px;
}

.inkox-image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for a polished look */
}

/* Main container holding both sidebar and main content */
.inkox-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  width: 100% !important;
  column-gap: 20px;

  padding: 10px 0px 0px 0px; /* Added top/bottom padding for better structure */
}
.inkox-image-section img {
    max-width: 61% !important;
    height: auto;
}
.image-section-inkox img{
      max-width: 61% !important;

}
.inkox-main-content img{
   max-width: 61% !important;

}

.inkox-main-content h3 {
    font-size: 14px !important;
}

.main-inkoxpage-cls
{
  width: 96%;
  margin: 0 auto;

}
/* Main content area */
.inkox-main-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.7; /* Slightly increased line-height for readability */
  color: #555;
}

/* Menu items in sidebar */
.inkox-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;

  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.main-otherpagesub {
    border-bottom: 1px solid #aaa;
}
.inkox-menu-item:hover {

  color: #29b8e3;
}

.main-inkox-pages{

  background:#f5f5f5;

}

.inkox-plus-sign {
  font-size: 18px;
  color: #999;
}

/* List Links Section */
.inkox-links-section {
  margin-top: 20px;
}

.inkox-links-section ul {
  list-style-type: disc;
  padding-left: 10px;
}

.inkox-links-section li {
  margin-bottom: 10px;
}

.inkox-links-section a {
  color: #0056b3;
  text-decoration: none;
  transition: text-decoration 0.3s ease, color 0.3s ease;
}

.inkox-links-section a:hover {
  text-decoration: underline;
  color: #004499;
}

/* Responsive styles for mobile */
@media (max-width: 1024px) {
  .inkox-container {
    flex-direction: column;
    width: 90%;
  }

  .inkox-sidebar {
    width: 100% !important;
    padding: 15px;
  }

  .inkox-main-content {
    padding: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .inkox-sidebar {
    width: 100% !important;
    padding: 10px;
  }

  .inkox-menu-item {
    padding: 12px 0px;
    font-size: 15px;
  }

  .inkox-submenu {
    display: none;
  }

  .inkox-menu-item.open .inkox-submenu {
    display: block;
  }

  .inkox-main-content {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .inkox-sidebar {
    width: 100% !important;
    padding: 10px;
    box-sizing: border-box;
  }

  .inkox-main-content h1 {
    font-size: 24px;
  }

  .inkox-main-content p {
    font-size: 14px;
  }

  .inkox-sidebar ul li {
    font-size: 14px;
  }

  .inkox-sidebar h3,
  .inkox-sidebar h4 {
    font-size: 16px;
  }
}

