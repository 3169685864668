/* App Layout */
.main-content-inkox {
  font-size: 16px;
  color: #333;
}


.inkox-content-h1{
    font-size: 24px !important;
}

.inkox-content-h2{
  font-size: 20px !important;
}

.inkox-content-h3{
  font-size: 18px !important;
}

.inkox-content-para{
  font-size: 14px !important;
}

.about-title-inkox {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #1f51cf;
}

.subtitle-inkox {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  color: #444;
}

.description-inkox {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
  color: #666;
}

.contact-title-inkox {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}

.browse-buttons-inkox {
  text-align: center;
  margin: 20px 0px 20px 0px;
}

.app-container-inkox {
  display: flex;
  font-family: Arial, sans-serif;
}


.main-content-inkox {
  flex-grow: 1;
  padding: 6px;
  margin-left: 0px;
  width: 100%;
  box-sizing: border-box;
}

.main-content-inkox h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.main-content-inkox h3 {
  margin-top: 20px;
  font-size: 24px;
  color: #444;
}

.main-content-inkox p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* Image Section */
.image-section-inkox {
  margin-top: 20px;
}

.support-image-inkox {
  width: 100%;
  border-radius: 5px;
}

/* Divider */
.divider-inkox {
  width: 80%;
  margin: 20px auto;
  border-bottom: 1px solid #aaa;
}

/* Button Styles */
.browsebut-inkox {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.primary-button-inkox:hover {
  background-color: #29b8e3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container-inkox {
    flex-direction: column;
    align-items: center;
    padding: 5px !important;
    box-sizing: border-box;
  }

  /* .sidebar-inkox {
    width: 100% !important;
    padding: 10px;
  } */

  .main-content-inkox {
    width: 100%;
  }
  .about-title-inkox {
      font-size: 24px;
    }
  
    .subtitle-inkox {
      font-size: 18px;
    }
  
    .description-inkox {
      font-size: 14px;
    }
  
    .support-image-inkox {
      max-width: 100%;
    }
  
    .primary-button-inkox {
      font-size: 12px;
      padding: 8px 16px;
    }
}
