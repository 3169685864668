.title {
  color: $black-clr1;
  * {
    all: unset;
    &:hover {
      all: unset;
    }
  }
  // &.fs-117 {
  //   font-size: 7.313rem;
  // }
  // &.fs-65 {
  //   font-size: 4.063rem;
  // }
  // &.fs-46 {
  //   font-size: 2.875rem;
  // }
  // &.fs-42 {
  //   font-size: 2.625rem;
  // }
  // &.fs-41 {
  //   font-size: 2.563rem;
  // }
  // &.fs-31 {
  //   font-size: 1.938rem;
  // }
  // &.fs-27 {
  //   font-size: 1.688rem;
  // }
  // &.fs-25 {
  //   font-size: 1.563rem;
  // }
  // &.fs-22 {
  //   font-size: 1.375rem;
  // }
  // &.fs-21 {
  //   font-size: 1.313rem;
  // }
  // &.fs-20 {
  //   font-size: 1.25rem;
  // }

  // &.fs-18 {
  //   font-size: 1.125rem;
  // }

  // &.fs-17 {
  //   font-size: 1.063rem;
  // }

  // &.fs-14 {
  //   font-size: 0.875rem;
  // }
  // &.fs-13 {
  //   font-size: 0.813rem;
  // }
  // &.fs-12 {
  //   font-size: 0.75rem;
  // }

  // &.fw-700 {
  //   font-weight: 700;
  // }

  // &.fw-600 {
  //   font-weight: 600;
  // }

  // &.fw-500 {
  //   font-weight: 500;
  // }

  // &.fw-400 {
  //   font-weight: 400;
  // }

  // &.fw-300 {
  //   font-weight: 300;
  // }
}
