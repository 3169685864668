.profileForm {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: $offwhite-clr1;
  padding-top: 5rem;
  padding-bottom: 10rem;

  .ant-upload-picture-circle-wrapper {
    overflow: hidden;
    margin: auto;
    text-align: center;
    .ant-upload {
      &.ant-upload-select {
        height: 15rem;
        width: 15rem;
      }
    }
  }

  form {
    max-width: 800px;
    margin: auto;
    .password {
      margin-bottom: 1rem;
    }
    .simpleButton {
      width: 100%;
      padding-inline: 0;
      font-size: 1.2rem;
      margin-top: 1rem;
      &:hover {
        padding-inline: 0;
        background-color: #fff;
      }
    }
  }
}
