.productDeliveryProcess {
  margin: 5rem 0;
  .description {
    font-weight: 400;
  }
  .customStepsForForm {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid $black-clr1;
    border-right: 0;
    .ant-steps-item {
      width: 33%;
      min-height: 76px;
      position: relative;

      .title {
        color: #2daae1;
      }
      &::after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: -8px;
        right: 4px;
        border: solid transparent;
        border-left-color: $white-clr;
        border-width: 45px;
        transform: translateX(45px);
        -webkit-transform: translateX(45px);
        -moz-transform: translateX(45px);
        -ms-transform: translateX(45px);
        -o-transform: translateX(45px);
      }
      &::before {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: -8px;
        right: 0;
        border: solid transparent;
        border-left-color: $black-clr1;
        background-color: #fff;
        border-width: 45px;
        transform: translateX(45px);
        -webkit-transform: translateX(45px);
        -moz-transform: translateX(45px);
        -ms-transform: translateX(45px);
        -o-transform: translateX(45px);
      }
      &:last-child {
        &::after,
        &::before {
          display: none;
        }
      }
      .ant-steps-item-content {
        min-height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-steps-item-title::after,
      .ant-steps-item-icon {
        display: none;
      }
      &.ant-steps-item-active {
        background-color: #9fccff;
        .title {
          color: $black-clr1;
        }
        &::after {
          border-left-color: #9fccff;
        }
      }
    }
  }

  .blueBtn {
    font-size: 1.438rem;
    font-weight: 400;
    color: $white-clr;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 25.688rem;
    min-height: 3.5rem;
    background-color: #27a6ff;
    border: 1px solid #27a6ff;
    border-radius: 0.688rem;
    -webkit-border-radius: 0.688rem;
    -moz-border-radius: 0.688rem;
    -ms-border-radius: 0.688rem;
    -o-border-radius: 0.688rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    &:hover {
      color: #27a6ff;
      background-color: $white-clr;
    }
  }

  .left-content {
    .description {
      font-size: 1.313rem;
      &.para-one {
        margin: 2rem 0 1.5rem 0;
      }
      &.para-two {
        font-size: 1.25rem;
        line-height: 1.688rem;
        margin-bottom: 1rem;
      }
    }
    .blueBtn {
      margin: 2rem 0;
    }

    .ant-form-item {
      label {
        font-size: 1.25rem;
        line-height: 1.688rem;
      }
      .ant-form-item-required {
        position: relative;
        &::before {
          font-size: 1rem !important;
          position: absolute;
          top: 0;
          right: -0.5rem;
        }
      }
      .ant-input[type="text"] {
        min-height: 3.313rem;
        border: 3px solid #eeeeee;
        background-color: #f5f5f5;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        box-shadow: 0 3px 1px #9fccff;
      }
    }
  }
  .para-three {
    font-size: 1rem;
  }
  .timings {
    margin: 0.8rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    .description {
      padding-left: 1rem;
      border-left: 1px solid #707070;
      &:first-child {
        padding-left: 0;
        border: none;
      }
    }
  }

  .product-info-box {
    padding: 1.5rem;
    box-shadow: 0 10px 6px #9fccff;
    background-color: #f5f5f5;
    .product-name {
      border-bottom: 1px solid $border-clr;
    }
    .product-feature {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
      padding-inline: 2rem;
      label {
        font-size: 1.313rem;
        font-weight: 600;
      }
      .description {
        font-size: 1.188rem;
      }
    }
    .inline-row {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 2rem;

      border-bottom: 1px solid $border-clr;
      gap: 1rem;
      .images {
        img {
          width: 8.188rem;
          height: 8.313rem;
        }
      }
      .product-size-color {
        > div {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;
          label {
            font-size: 1.625rem;
            font-weight: 600;
          }
          .description {
            display: flex;
            gap: 1rem;
            align-items: center;
            font-size: 1.438rem;
            .color-box {
              display: block;
              height: 1.5rem;
              width: 1.5rem;
              margin-bottom: 0;
              background-color: #ece7e7;
              border: 1px solid $border-clr;
              border-radius: 0.313rem;
              -webkit-border-radius: 0.313rem;
              -moz-border-radius: 0.313rem;
              -ms-border-radius: 0.313rem;
              -o-border-radius: 0.313rem;
            }
          }
        }
      }
    }
    .quantity-price {
      padding-inline: 2rem;
      margin: 2rem 0;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;
        .total-heading {
          color: #9f9f9f;
        }
        .actual-price {
          color: #9f9f9f;
          text-decoration: line-through;
        }
        .discounted-price {
          color: #2daae1;
        }
        .description {
          font-size: 1.188rem;
        }
      }
    }
    .blueBtn {
      margin: 3rem auto 1rem auto;
    }
  }
}
