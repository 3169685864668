button.design-now-btn {
    background: #fcc950;
    display: block;
    padding: 12px;
    color: #ffffff !important;
    border-radius: 4px;
    border: 0;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
}
.trendingProducts .card-content {
  background: transparent !important;
}
.main-row-product-detail h4 {
  /* background: red; */
  text-align: center;
  padding-bottom: 19px;
}
.bordermain-productpage{

border: 1px solid #dadadaaa;
padding: 12px;
padding-top: 36px;

}
ul.additional-information li{

  font-size: 14px;
}
button.design-now-btn h6 {
    color: #171616;
    text-align: center;
    font-weight: 600 !important;
}
a.design-now-btn {
  background: #1cc0d9;
  display: block;
  padding: 12px;
  color: #ffffff !important;
  border-radius: 4px;
  border: 0;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
}

a.design-now-btn h6 {
  color: #ffffff;
  text-align: center;
  font-weight: 600 !important;
}
.product-name {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .change-product-link {
    color: #1e88e5;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 15px;
    display: block;
  }
  
  .quote-section {
    margin-bottom: 20px;
  }
  
  .quote-side {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quote-side p {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .outside-link {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: #1e88e5;
    text-decoration: underline;
    cursor: pointer;
  }
  
  hr {
    margin: 20px 0;
  }
  
  .ant-btn {
    height: 40px;
    font-size: 16px;
  }
  .size-container.color {
    margin-top: 15px;
}
.colors {
  margin: 0 !important;
}
.trendingProducts .ant-row {
  margin-top: 40px !important;
}
.trendingProducts{

  padding-bottom: 3rem;
}
.size-container.sizes-product-page {
  margin-top: 8px;
}
a.design-now-btnh6.title.undefined.fs-18.text-undefined.fw-700 {
  color: #ffffff;
  font-size: 15px;
}
.sizes ul {
  padding-top: 9px !important;
  margin-bottom: 0 !important;
}
.links-text {
  margin: 8px 0px;
}
.productSlider img.main-img {
  margin: 0;
}