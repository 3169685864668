.simpleCard {
  background-color: $offwhite-clr1;
  box-shadow: 0px 6px 7px $shadow-clr;
  border: 1px solid #f5f5f5;

  &:hover .card-image img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  .card-image {
    height: 20rem;
    padding: 1rem;
    background-color: $white-clr;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
    }
    .view-btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.4rem;
      background-color: $yellow-clr2;
      z-index: 1;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    * {
      width: 100%;
    }
    .category {
      color: $offwhite-clr5;
    }

    .price {
      color: $skyblue-clr3;
    }
  }

  &.filterCard {
    padding: 0 2rem;
    position: relative;
    background-color: $white-clr;
    .card-image {
      overflow: hidden;
      padding: 0;
      height: 23.625rem;
    }
    .topSeller {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 6.5rem;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
      background-color: $gray-clr5;
      color: $white-clr;
      z-index: 1;
      text-align: center;
    }
    .card-body {
      display: flex;
      padding-bottom: 1rem;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $white-clr;
      .view-btn {
        width: 100%;
        padding: 1rem 0.4rem;
        background-color: #f5f5f5;
        color:#1f1f2b;
        z-index: 1;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        // display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .reviewsContainer {
        display: flex;
        align-items: baseline;
        gap: 1rem;
      }
      .size-price-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }

  &.categoryCard {
    box-shadow: none;
    box-shadow: 0px 0px 9px #ecf0f1;
    margin-bottom: 30px;
    .card-image {
      overflow: hidden;
      padding: 0;
      height: auto;
      // height: 30.625rem;
      // box-shadow: 0px 3px 20px $shadow-clr;
      background-color: #ecf0f1;
      border-radius:0px ;
    }

    .card-content {
      padding: 1rem 0;
      background-color: $white-clr;
      font-family: Barlow Condensed,sans-serif !important;
      .view-btn {
        width: 100%;
        padding: 1rem 0.4rem !important;
        // background-color: $yellow-clr2;
        font-size: 22px;
        color: #000 !important;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        box-sizing: border-box;
        -webkit-box-orient: vertical;
        font-family: Barlow Condensed,sans-serif !important;
      }
    }
  }
}

.color-preview {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.color-swatch {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 3px;
  cursor: pointer;
}

@media screen and (max-width:900px) {

  .simpleCard.categoryCard .card-image{
    height: 16rem !important;
  }
  .simpleCard.categoryCard .card-content .view-btn{

    font-size: 13px;
  }

  
}
