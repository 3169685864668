.signUpForm {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: $offwhite-clr1;
  .left-content {
    width: 40%;
    .logo {
      margin: 1rem auto 5rem auto;
      display: block;
      margin: 0 auto;
      width: 200px;
    }
    form {
      .simpleButton {
        width: 100%;
        padding-inline: 0;
        font-size: 1.2rem;
        margin-top: 3rem;
      }
      .login-account-btn {
        display: block;
        width: max-content;
        color: $skyblue-clr6;
        text-decoration: underline;
        margin-left: auto;
      }
    }
  }
  .right-content {
    height: 100%;
    width: 60%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
