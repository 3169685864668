/* App Layout */
.inkox-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
}
.inkox-provide{
  font-size: 14px;
}

.inkox-main-content h3 {
  font-size: 20px;
}

.inkox-content-h2{
  font-size: 20px;
}
.inkox-main-content p {
  font-size: 14px !important;
  line-height: 1.6;
}


.inkox-content-para{
  font-size: 14px;
}

.inkox-main-content h1 {
  font-size: 20px;
  margin-top: 0;
}

/* Sidebar Styling */
/* .inkox-sidebar h2 {
  margin-bottom: 15px;
}

.inkox-sidebar ul {
  list-style: none;
  padding: 0;
}

.inkox-sidebar li {
  margin-bottom: 10px;
}

.inkox-sidebar a {
  text-decoration: none;
  color: #333;
} */

.inkox-main2 {
  width: 80%;
  display: flex;
  margin: 0 auto;
}

.inkox-undermain {
  width: 25%;
}

.inkox-browsebut {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.inkox-primary-button:hover {
  background-color:#29b8e3;
}

.inkox-divider {
  width: 80%;
  margin: 0 auto;
}

/* Main Content Styling */


.inkox-main-content h1 {
  font-size: 24px;
  margin-top: 0;
}

.inkox-image-section {
  margin-top: 20px;
}

.inkox-image-section img {
  max-width: 100%;
  height: auto;
}

.inkox-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  width: 96%;
  margin: 0 auto;
}

.inkox-support-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  
  .inkox-main-content {
    padding: 5px !important;
    box-sizing: border-box;
  }
}