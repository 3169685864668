.socialIcons {

  align-items: center;
  gap: 0.5rem;
  a {
    img {
      height: 3.125rem;
      width: 3.125rem;
    }
  }
}
