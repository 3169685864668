.active-filtersss {
    margin-left: 17px;
}
.active-filtersss span {
    background: #1cc0d9;
    color: #ffffff;
}
:where(.css-dev-only-do-not-override-nllxry).ant-tag .ant-tag-close-icon {
    margin-inline-start: 3px;
    color: rgb(255 255 255);
    font-size: 10px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: bolder;
}