/* App Layout */

.Inkox-content-h2 {
  font-size: 20px;
}
.Inkox-content-h3 {
  font-size: 18px;
}
.Inkox-content-para {
  font-size: 14px;
}
.inkox-app-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
}
.inkox-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  width: 96%;
  margin: 0 auto;
}



/* Main Content Styling */
.inkox-main-content {
  flex-grow: 1; /* This will make the main content expand to fill remaining space */
  padding: 20px;
  margin-left: 10px; /* Add some spacing between sidebar and content */
}

.inkox-main-content h1 {
  font-size: 24px;
  margin-top: 0;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.6;
}

/* Divider Section */
.inkox-divider {
  width: 80%;
  margin: 0 auto;
}

.inkox-image-section img {
  max-width: 100%;
  height: auto;
}

/* Button Styling */
.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.inkox-primary-button:hover {
  background-color:#29b8e3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  
  .inkox-main-content {
    padding: 5px !important;
    box-sizing: border-box;
    margin-left: 0px; 
  }
}
