header {
  position: relative;
  box-shadow: 0px 10px 30px $shadow-clr;
  .sideBarIcon {
    font-size: 2rem;
  }


  .mobile-menu {
    padding-bottom: 0rem;
  }
  .brand-logo {
 
    padding-bottom: 0rem;
    width: 100%;
    max-width: 15rem;
    img {
      width: 85%;
    }
  }

  .ant-menu {
    border: none !important;
    height: 70px;
    .ant-menu-item {
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      height: 100%;
      .ant-menu-title-content {
        color: $black-clr1 ;
        display: flex;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        height: auto;
        font-size: 17px;
          padding: 0px 5px;

        color: $black-clr1;
        text-transform: capitalize !important;
      }
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        color: $black-clr1 !important;


     
      }


    }



    .ant-menu-submenu {
      display: flex;
      align-items: center;
      height: 100%;
      padding-bottom: 0;
      font-size: 1.313rem;
      color: $black-clr1;
      text-transform: capitalize !important;
      .ant-menu-submenu-title,
      .ant-menu-title-content {
        color: $black-clr1 !important;
        display: flex;
          padding: 0px 10px;

        font-weight: 400;
        align-items: center;
        justify-content: center;
                font-size: 17px;

        height: auto;
      }
      &.ant-menu-submenu-selected,
      &.ant-menu-submenu-active {
        color: $black-clr1 !important;
    
        &::before {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 90%);
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $yellow-clr1;
          -webkit-transform: translate(-50%, 90%);
          -moz-transform: translate(-50%, 90%);
          -ms-transform: translate(-50%, 90%);
          -o-transform: translate(-50%, 90%);
        }
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: start;

    li {
      a {
        font-size: 1.313rem;
        color: $black-clr1;
        text-transform: capitalize !important;
      }
    }
  }
  // .wayOfContact {
  //   padding-bottom: 1rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   gap: 1.5rem;
  //   width: 100%;
  //   .box {
  //     display: flex;
  //     gap: 0.4rem;
  //     align-items: center;
  //     img {
  //       height: 1rem;
  //     }
  //   }
  // }
}

.top-nav{
  


  bacground-color:#1cc0d9;
}
.header-sidebar {
  .ant-drawer-body {
    padding-inline: 0 !important;
    .ant-menu-item {
      margin: 0;
      width: 100%;
      text-transform: capitalize !important;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      &.ant-menu-item-selected {
        color: $white-clr !important;
        background-color: $yellow-clr1 !important;
      }
    }
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow,
      .ant-menu-title-content {
        color: $black-clr1 !important;
      }
    }
    .ant-menu-sub {
      .ant-menu-item-selected {
        color: $white-clr !important;
        background-color: $yellow-clr1 !important;
      }
    }
  }
}
