.brandDeserves {
  padding: 3rem 1rem;
  text-align: center;
  .container {

    margin: auto;
    padding-top: 2rem;
    border-top: 0.813rem solid $skyblue-clr2;
  }
  .sub-title {
    margin-top: 0.5rem;
  }
  .brands-container {
    margin-top: 3rem;
    border-right: none;
    .brandCard {

    }
  }
}
