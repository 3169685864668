.simpleButton {
  cursor: pointer;
  color: $black-clr1;
  display: block;
  border: 1px solid $border-clr;
  text-align: center;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  &.small {
    min-height: 3.25rem;
    padding: 0 1.5rem;

    &:hover {
      padding: 0 2rem;
    }
  }
  &.large {
    min-height: 4.875rem;
    padding: 0 1.5rem;
    &:hover {
      padding: 0 2rem;
    }
  }
}
