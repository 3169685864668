.productListing {
  padding: 5rem 0;
  .title-section {
    text-align: center;
    .sub-title {
      margin-top: 0.5rem;
    }
  }

  .page-header {
    margin-bottom: 4rem;
  }
}
