.clipart-component2 {
  margin-top: 0px;
  position: relative;
  background-color: #ecf0f1;
  margin-bottom: 8px;
  padding: 6px 0px;
}

.clipart-categories {
  display: grid;
  justify-content: center;
  width: 100%;

  gap: 10px;
  margin-top: 18px;
  margin-bottom: 18px;
  grid-template-columns: 50% 50%;
}
.pagination-controls {
  text-align: center;
  padding-top: 14px;
}
.clipart-gallery {
display: grid;
justify-content: space-between !important;

width: 100%;
gap: 10px;
margin-top: 18px;
margin-bottom: 18px;
flex-direction: row;
grid-template-columns: 33.33% 33.33% 33.33%;
}
.clipart-subcategories {
  display: grid;

  gap: 13px;
  margin-top: 18px;
}

.clipart-category {
width: 100%;
height: 87px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
border: none;
border-radius: 5px;
text-align: center;

background: #137d97 !important;
color: white;
font-weight: bold;
}



.clipart-thumbnail {
  width: 100%;
  height: 80px;
  cursor: pointer;
}

.colors-section {
  margin-top: 0px;
}

.colors-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color-box {
  width: 25px;
  height: 25px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #c5c5c7 !important;
}

.color-picker {
  position: absolute;
  z-index: 2;
}
