/* Style for the modal */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    width: 80%; /* Adjust width as per your requirement */
    max-width: 600px;
    border-radius: 8px;
  }
  
  /* Style for the overlay (backdrop) */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .modal-background-color {
    background: white;
    height: -webkit-fill-available;
    /* margin-bottom: 25px; */
    margin: 53px;
    position: relative;
}
.modal-content {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.top-nav-popup-tool {
  display: flex;
  justify-content: space-between;
  background: #1CBED9;
  padding: 5px;
  color: white;
  align-items: center;
}
button.close-buttonspopup {
  background: transparent;
  border: 0;
  cursor: pointer;
}
button.close-buttonspopup img {
  width: 20px;
}