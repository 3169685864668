.newArrival {
  padding: 0;
  padding-top:0px;

  .ant-row {
 
      height: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;


  
  }

  img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .box {
    height: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover img {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
    }

    .simpleButton {
      bottom: 1.5rem;
      left: 1.5rem;
      padding: 0 0.5rem;
      position: absolute;
      z-index: 1;
      .title {
        // padding: 0.01rem 0.5rem 0.01rem 0.5rem;
        padding: 0 1rem;
        &:nth-child(2) {
        }
        &:first-child {
          margin-bottom: 0.2rem;
        }
      }
    }
    &.three {
      .title {
        position: relative;
        &:nth-child(2) {
          color: $white-clr;
          &::before {
            display: block;
            content: "";
            background-color: $black-clr1;
            height: 90%;
            width: 100%;
            position: absolute;
            top: 0.1rem;
            left: 0;
            z-index: -1;
          }
        }
      }
    }
  }
}

.shippling-arrival .ant-row {
  align-items: center;

  
}
.newArrival .box .simpleButton{

  display: none;
}
h2.newarrivalorignal-text {
  text-align: center;
  font-size: 2.563rem;
  font-weight: 700;
}
.newarrivalorignal {
  margin: 25px 0px;
}
.main-text-newarival {
  text-align: center;
  margin-bottom: 38px;
  margin-top: 5rem;
}

.main-text-newarival .sub-title {
  margin-top: .5rem;
}