$black-clr1: #000000;
$black-clr2: #110f27;
$white-clr: #ffffff;
$yellow-clr1: #fcc950;
$yellow-clr2: #fcc83b;
$yellow-clr3: #fff7e3;
$offwhite-clr1: #f9f9f9;
$offwhite-clr2: #f0f2f5;
$offwhite-clr3: #f6f6f6;
$offwhite-clr4: #c8c8c8;
$offwhite-clr5: #d4d4d4;
$shadow-clr: #00000029;
$border-clr: #707070;
$border-clr2: #d8d8d8;
$gray-clr1: #787878;
$gray-clr2: #abacae;
$gray-clr3: #c1c1c1;
$gray-clr4: #eff2f9;
$gray-clr5: #575757;
$gray-clr6: #f8f8f8;
$gray-clr7: #f6f7f8;
$gray-clr8: #c1c7cf;
$gray-clr9: #e6e6e6;
$gray-clr10: #eeecea;
$skyblue-clr1: #2daae1;
$skyblue-clr2: #4ea4c9;
$skyblue-clr3: #44b2e4;
$skyblue-clr4: #71bee7;
$skyblue-clr5: #0aadb7;
$skyblue-clr6: #249fd5;
$skyblue-clr7: #dcf4ff;
$red-clr1: #dd2727;
$red-clr2: #fa3f0a;
$red-clr3: #ee592d;
