/* App Layout */
.inkox-app-container {
  display: flex;
  font-family: Arial, sans-serif;
}

/* Sidebar Styling */
/* .inkox-sidebar {
  width: 20%; 
  padding: 20px;
  background-color: #f1f1f1;
}
.inkox-sidebar {
  width: 26% !important;
}

.inkox-sidebar h2,
.inkox-sidebar h3,
.inkox-sidebar h4 {
  margin-bottom: 15px;
  color: #333;
}

.inkox-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.inkox-sidebar ul li {
  margin-bottom: 10px;
  font-size: 15px;
  color: #0073e6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inkox-sidebar ul li:hover {
  text-decoration: underline;
} */

/* Main Container */
.inkox-container {
  display: flex;
  width: 90%; /* Full width of the parent */
  margin: 0 auto;
}

/* Main Content Styling */
.inkox-main-content {
  flex-grow: 1;
  padding-top: 6px;
  margin-left: 0; /* No left margin */
  width: 100%; /* Main content takes 80% of the width */
  max-width: 100% !important;
}

.inkox-main-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.inkox-main-content h3 {
  margin-top: 20px;
  font-size: 24px;
  color: #444;
}

.inkox-main-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* Image Section */
.inkox-image-section {
  margin-top: 20px;
}

.inkox-support-image {
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}

/* Divider */
.inkox-divider {
  width: 80%;
  margin: 20px auto;
  border-bottom: 1px solid #aaa;
}

/* Button Styles */
.inkox-browsebut {
  text-align: center;
  padding: 16px 6px;
}

.inkox-primary-button {
  background-color: #29b8e3;
  color: #fff;
  padding: 16px 25px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.inkox-primary-button:hover {
  background-color: #29b8e3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .inkox-container {
    flex-direction: column;
    align-items: center;
  }


  .inkox-main-content {
    width: 100%; /* Main content takes full width on mobile */
    margin-left: 0;
    padding: 5px !important;
    box-sizing: border-box;
  }

  .inkox-divider {
    width: 90%;
  }

  .inkox-primary-button {
    margin: 10px 0;
    width: 80%;
  }
}

