.product-color-component {
  margin-top: 20px;
}

button.selected img {
  filter: opacity(0.2);
}

.icon-row button:hover {
  background: #f6f6f6 !important;
}
.color-box:has(.cross-icon) {
  pointer-events: none;
}


button.button-to-text:hover {
  background: #f5f5f5 !important;
  color: #1cc0d9 !important;
}
label.selectarc {
  margin-bottom: 15px;
  display: block;
}
button.viewfontcat {
  background: transparent;
  border: 0;
  color: #1cc0d9;
  cursor: pointer;
  position: relative;
}
button.font-display-button.font-name::before {
  content: '';
  display: inline-block;
  background-image: url('../assets/arrow.svg'); /* Path to your PNG image */
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px; /* Adjust based on the image size */
  height: 13px; /* Adjust based on the image size */
  position: absolute;
  right:0;
  top: 50%;
  transform: translateY(-50%);
}
button.font-display-button.font-name{
  position: relative;
  padding-right: 27px;
}

.outline-main::before {
  content: '';
  display: inline-block;
  background-image: url('../assets/arrow.svg'); /* Path to your PNG image */
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px; /* Adjust based on the image size */
  height: 13px; /* Adjust based on the image size */
  position: absolute;
  right:0;
  top: 50%;
  transform: translateY(-50%);
}
.outline-main{
  position: relative;
  padding-right: 27px;
  display: flex;
}
img.top-icon {
  width: 60%;
  box-sizing: border-box;
  vertical-align: middle;
  /* display: flex; */
  /* margin: 0 auto; */
}

button.font-display-button{
  position: relative;
  padding-right: 27px;
  
}
button.font-display-button::before {
  content: '';
  display: inline-block;
  background-image: url('../assets/arrow.svg'); /* Path to your PNG image */
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px; /* Adjust based on the image size */
  height: 13px; /* Adjust based on the image size */
  position: absolute;
right:0;
  top: 50%;
  transform: translateY(-50%);
}



button.viewfontcat::before {
  content: '';
  display: inline-block;
  background-image: url('../assets/blue-arrow-png-36989.png'); /* Path to your PNG image */
  background-size: contain;
  background-repeat: no-repeat;
  width: 12px; /* Adjust based on the image size */
  height: 13px; /* Adjust based on the image size */
  position: absolute;
  left: -11px; /* Adjust for spacing */
  top: 50%;
  transform: translateY(-50%);
}
.innerdonearc{
display: flex;
justify-content: space-between;

}
button.button-to-texts {
  border: 1px solid #1cc0d9 !important;
  font-weight: bold;
  margin-top: 10px;
  background-color: rgb(28, 190, 217);
  box-shadow: none;
  color: white;
  border: none;
  border-radius: 7px;
}
.flex-uploadimage{

  display: flex;
  justify-content: space-between;
}
.innerdone {
  text-align: right;
}
img.closeicon {
  width: 13px;
  height: 13px;
}
input#text_value {
  width: 100% !important;
  border-radius: 6px;
  padding: 10px 10px 10px 90px;
  border: 1px solid rgba(170, 170, 170, 0.667);
  box-sizing: border-box;
  margin-top: 18px;
  font-size: 18px;
}

/* Now */

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.search-box svg {
  margin-right: 8px;
}

.font-search {
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #333;
}

.font-search::placeholder {
  color: #aaa;
}


.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.upload-box {
  border: 1px dashed #e1e1e1;
  text-align: center;
  width: 70%;
  border-radius: 2px;
  margin-top: 10px;
  padding: 38px 32px;
}
.upload-icon svg {
  fill: #666;
  width: 25%;
}

.upload-text {
  /* margin-top: 10px; */
  font-size: 17px;
  color: #202b40;
}

.browse-text {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.file-types {
  margin-top: 25px;
  font-size: 12px;
  color: #666666;
}

.font-search{
  box-sizing: border-box;
}
.highart{
  padding: 10px 12px;
}
.highfont {
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  color: #666666;
  padding-top: 19px;
}





:where(.css-dev-only-do-not-override-1pg9a38).ant-switch.ant-switch-checked {
  background: #1cbed9;
}
.font-display-button{
  font-weight: 300 !important;

}
.sidebar{
  padding: 0px;
}
button.color-box.none-btn-outline {
  border: 0 !important;
  color: gray;
  text-transform: capitalize;
}

/* Spacing */
/* Webkit browsers (Chrome, Safari, Edge) */

.range-bar {
  -webkit-appearance: none; /* Remove default styling */
  height: 4px; /* Set the thickness to 0.5px */
  background: #1cc0d9;
  border-radius: 5px;
}

.range-bar::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default thumb styling */
  width: 20px; /* Adjust the thumb width */
  height: 20px; /* Adjust the thumb height */
  border-radius: 50%; /* Make the thumb circular */
  background: #ffffff; /* Thumb color */
  border:1px solid gray;
  cursor: pointer; /* Change cursor to pointer */
}

.range-bar::-moz-range-thumb {
  width: 20px; /* Adjust the thumb width */
  height: 20px; /* Adjust the thumb height */
  border-radius: 50%; /* Make the thumb circular */
  background: #ffffff; /* Thumb color */
  border:1px solid gray;
  cursor: pointer; /* Change cursor to pointer */
}

.range-bar::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  border:1px solid gray;
}


/* //Rotation// */
.color-palettess {
  padding: 6px 6px;
}
.inputedit{
  border-radius: 6px;
  border: 1px solid #aaa;
  width: 92% !important;
}
.inputdiv{
  border-bottom: 1px solid #aaa;
  padding-bottom: 6px;
}

.font-display-button{
  font-size: 16px !important;
  font-weight: 600;
  cursor: pointer;
}
.icon-row {
  padding: 8px 6px;
  /* margin-top: 0px !important; */
  /* border: 1px solid #aaaa; */
  margin: 17px 0px !important;
  border-radius: 7px;
}

input[type="range"] {
  width: 100%;
  margin-top: 5px;
  height: 4px;
}


.labFont {
  font-size: 15px !important;
  padding: 14px 6px !important;
  margin-top: 0px !important;
  color: #202b40 !important;
}
.placeholderStyle::placeholder {
  color: #aaaa; 
  box-sizing: border-box;
}
.placeholderStyle:focus {
  border-color: #d8d8d8b7;
  outline: none; 
}


.clipart-component {
  padding: 18px 18px;
}
.color-options {
  display: flex;
  flex-wrap: wrap;
}
button.color-display-button {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 1px solid #c5c5c7 !important;
  border-radius: 4px;
}
sidebar{
  padding: 0px;
}
.color-option {
  width: 30px;
  height: 30px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  text-align: center;
}
.color-picker {
margin-top: 10px;
border: 1px solid #ccc;
padding: 10px;
background-color: #fff;
}

.color-grid {
display: flex;
flex-wrap: wrap;
}
.inner {
  grid-template-columns: 30% 70%;
  display: flex;
      border-bottom: 1px solid #e2dede;

      align-items: center;
      justify-content: space-between;
      
padding:3px 0px;
}

.color-box {
width: 25px;
height: 25px;
margin: 5px;
cursor: pointer;
border: none !important;
border-radius: 3px;
}

.icon-row {
display: flex;
justify-content: space-around;
margin-top: 10px;
}

.icon-row button {
background: none;
border: none;
cursor: pointer;
font-size: 20px;
}

input#text_value:focus{
  outline: 1px solid #1cbed9;
  border:0;


}
.innerdisplaydonearc{
  display: none;
}
.main-clipart{
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 18px;
}

.detail-text-box {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 6px;
}
.detail-text-box-header {
  padding-top: 20px;
}
.detail-text-box-header {

justify-content: space-between;
align-items: center;
}

.input-text {
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
}

.icon-row {
  display: flex;
  justify-content: space-between;
  /* border: 0; */
  background: #f9f9f980;
}

.icon-row button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border: 1px solid #dfd6d6;
  padding: 3px;
  box-sizing: border-box;
  margin: 4px 12px;
  border-radius: 4px;
  vertical-align: middle;
  align-items: center;
  /* background: red; */
}

/* input[type="range"] {
  width: 100%;
  margin: 10px 0px;
} */

/* .detail-text-box-body {
margin-top: 10px;
} */

.detail-text-box-body label {
display: block;
margin-top: 10px;
text-align: left;
width: 34%;
}
.warp-type-option.selected img {
  opacity: .3 !important;
}
.outline-settings .inner {
  padding: 10px 6px;
}

.selected-color-box{
  position: relative;
}

.selected-color-box:before {
  content: '';
  position: absolute;
  top: 57%;
  left: 58%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  
  /* Add the custom tick mark SVG as a background */
  background-image: url('../assets/tickmark.png'); /* Path to your PNG image */
  background-repeat: no-repeat;

  background-size: 90%; /* Adjust the size of the SVG */
}

.color-box.selected {
  position: relative;
}


.color-box.selected:before {
  content: '';
  position: absolute;
  top: 57%;
  left: 58%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  
  /* Add the custom tick mark SVG as a background */
  background-image: url('../assets/tickmark.png'); /* Path to your PNG image */
  background-repeat: no-repeat;

  background-size: 90%; /* Adjust the size of the SVG */
}


.color-box.outline-selected {
  position: relative;
}


.color-box.outline-selected:before {
  content: '';
  position: absolute;
  top: 57%;
  left: 58%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  
  /* Add the custom tick mark SVG as a background */
  background-image: url('../assets/tickmark.png'); /* Path to your PNG image */
  background-repeat: no-repeat;

  background-size: 90%; /* Adjust the size of the SVG */
}

.donebutton {
  background: #1cc0d9;
  padding: 9px 27px;
  border-radius: 4px;
  color: white;
  border: 0;
  font-weight: bold;
  margin-top: 18px;
}

.donebutton:hover{

  background: #f5f5f5 !important;
  color: #1cc0d9 !important;
  border: 1px solid #1cc0d9 !important;
}
span.inches {
  border: 1px solid #c5c5c7;
  padding: 6px 12px;
  margin-right: 4px;
  border-radius: 4px;
}
.color-box.outline-selected{
  position: relative;
}
button.font-display-button {
  background: transparent;
  font-size: 23px;
  border: 0;
  color: gray;
}
button.resetbtn {
  margin-left: 27px;
  background: #1cc0d9;
  padding: 4px 6px;
  border: 0;
  color: white;
  font-size: 9px;
  border-radius: 23px;
  /* position: absolute; */
  /* top: 0; */
  /* padding-top: 22px; */
}
.inner select {
  font-size: 23px;
  border: 0;
}
button.font-display-button.font-name {
 color: #000000 !important;
  font-size: 30px !important;
  font-weight: bolder !important;
}
.font-selector {
width: 100%;
padding: 5px;
/*  border: 1px solid #ccc;*/
border-radius: 4px;
margin-top: 5px;
box-sizing: border-box;
}

.color-picker {
margin-top: 10px;
border: 1px solid #ccc;
padding: 10px;
background-color: #fff;
}

.color-grid {
display: flex;
flex-wrap: wrap;
}

.color-box {
width: 25px;
height: 25px;
margin: 5px;
cursor: pointer;
border: 1px solid #c5c5c7 !important;
}

.font-name {
  font-size: 40px;
  color: #202b40;
}
.font-grid {
  text-align: center;
}
.font-box {
  font-size: 16px;
  border-bottom: 1px solid #aaaaaa40;
  cursor: pointer;
  color: gray;
}
.font-box:hover {
  
  background-color: #aaaaaa40;
  
}
input.font-selector-search-input {
  width: 100% !important;
}
input.font-search {
  display: block;
  width: 100%;
  padding: 10px;
}

input[type="range"] {
width: 100%;
margin-top: 5px;
}

.detail-text-box-body label {
  color: #000;
  font-size: 11px;
}

/* .image-palette {
  margin-top: 20px;
} */

.palette-colors {
  display: flex;
  flex-wrap: nowrap; /* Change from wrap to nowrap to keep colors in one line */
  gap: 5px; /* Reduce gap between colors */
}

.color-box {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin: 1px;
}

.color-picker {
  position: relative;
  margin-top: 20px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.color-box {
  width: 11px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  border: 2px solid black;
}

.color-box.selected {
  border: 2px solid #000; /* Border for selected box */
}

.color-box .tick-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  display: none; /* Hide tick mark by default */
}

.color-box.selected .tick-mark {
  display: block; /* Show tick mark for selected box */
}

.color-box .cross-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2px;
  right: 1px;
  font-size: 20px;
}
@media only screen and (max-width: 900px) {
  .tab-label {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* text-align: center; */
    font-size: 12px;
    text-align: center;
    color: #535c6c;
}
  
  .tab-label img {
    width: 28px;
    height: 28px;
  }
  /* .addcliparttwo{
    display: none !important;
  } */
  .warp-type-option label img { 
    width: 80px !important; 
    height: 40px !important; 
  }
  .warp-type-options {
    display: flex;
    flex-wrap: unset;
    justify-content: space-between;
    overflow-x: scroll;
}
.donebutton{
  padding: 7px 18px;

}
.horizon{
  display: none;
}
.innerdisplaydonearc{
  display: none;
}
  
.sidebar {
  z-index: 2;
  height: auto;
  max-height: 100%;
  overflow: auto;
}
}