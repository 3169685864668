.brandCard {
  height: 29.313rem;
      border: 2px solid #f5f5f5;
    margin: 8px;
    border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2rem;
  .card-image {
    height: 10rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-clr;
    position: relative;
    img {
      height: 4.438rem;
      width: 4.438rem;
      object-fit: contain;
    }
    .view-btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.4rem;
      background-color: $yellow-clr2;
      z-index: 1;
      text-align: center;
    }
  }

  .card-content {
    width: 100%;
    max-width: 85%;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    * {
      width: 100%;
    }
    .card-title {
      text-transform: uppercase;
      min-height: 2.7rem;
    }
    .description {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
.brandCard {

}

